import styled from '@emotion/styled';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import { ModalBody, ModalFooter } from '@ingka/modal';
import Text from '@ingka/text';
import { space75 } from '@ingka/variables';

export const FlexedModalFooter = styled(ModalFooter)`
  display: flex !important;
  padding: 27px !important;
`;

export const FlexedModalBody = styled(ModalBody)<{ margin?: string; width?: string }>`
  padding: ${space75};
  width: ${(props) => props.width};
  margin-left: ${(props) => props.margin};
  @media (min-width: 1200px) {
    width: 100%;
  }
`;

export const Menu = styled.div<{ leftPosition?: string }>`
  position: absolute;
  border: 1px solid #ddd;
  left: ${(props) => (props.leftPosition ? props.leftPosition : '-150px')};
  z-index: 2;
  background-color: white;
  border-radius: 8px;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const YellowButton = styled(Button)`
  color: #111111;
  background-color: #ffdb00;

  &:focus,
  &:hover {
    color: #111111;
  }

  .btn__inner {
    background-color: #ffdb00;
    border: none;
    &:active,
    &:hover {
      background-color: #ffdb00;
    }
  }

  &:hover {
    .btn__inner {
      box-shadow: none;
    }
  }
`;

export const DeleteErrorInlineMessage = styled(InlineMessage)`
  margin-bottom: 1rem;
`;

export const ConfirmDeleteText = styled(Text)`
  margin-bottom: 1rem;
`;
