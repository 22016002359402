import { FC, useState } from 'react';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import dataChartIcon from '@ingka/ssr-icon/paths/data-chart';
import Button from '@ingka/button';
import MonthCalendar, { monthOptions } from 'components/Calendar/MonthCalendar';
import { useFilter } from 'hooks/contexts/FilterContext';
import * as Styled from './styled';

const getCurrentYear = (): number => new Date().getFullYear();
const getCurrentMonth = (): string => monthOptions[new Date().getMonth()].value;

interface UsageRangeModal {}

const UsageRangeModal: FC<UsageRangeModal> = () => {
  const { filter, setFilter } = useFilter();
  const [open, setOpen] = useState<boolean>(false);
  const [from, setFrom] = useState<{ month: string; year: number }>({
    year: filter.year || getCurrentYear(),
    month: filter.month || getCurrentMonth(),
  });
  const [to, setTo] = useState<{ month: string; year: number }>({ year: filter.year || getCurrentYear(), month: filter.month || getCurrentMonth() });

  const handleConfirm = () => {
    setOpen(!open);
    setFilter((prev) => ({ ...prev, year: from?.year, month: from?.month }));
  };

  return (
    <>
      <Button type="tertiary" text={'Usage'} size="small" ssrIcon={dataChartIcon} onClick={() => setOpen(true)} />
      <Modal
        visible={open}
        handleCloseBtn={() => {
          setOpen(!open);
        }}
      >
        <Prompt
          titleId={'Redirect usage filter'}
          title={'Redirect usage filter'}
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                text={'Cancel'}
                size="small"
                type={'secondary'}
                onClick={() => {
                  setOpen(!open);
                }}
              />
              <Button text={'Confirm'} type={'primary'} size="small" onClick={handleConfirm} />
            </ModalFooter>
          }
        >
          <p>Month picker gives you the total amount of redirects between (and including) the selected timespan.</p>
          <Styled.FlexCalendar justify="space-between" mt={2}>
            <MonthCalendar label="From" value={from} onUpdate={setFrom} />
            <MonthCalendar
              label="To"
              value={to}
              onUpdate={setTo}
              disabled={true}
              tooltip='Feature for changing "to" is in development and should arrive shortly.'
            />
          </Styled.FlexCalendar>
        </Prompt>
      </Modal>
    </>
  );
};

export default UsageRangeModal;
