import React from 'react';
import { TableBody as Body } from '@ingka/table';
import Text from '@ingka/text';
import { OmitTN } from 'opt-util/types';
import { MetaDataCompleteFragment } from '__generated__/types';

interface TableBodyProps {
  entry: OmitTN<MetaDataCompleteFragment>;
}

export const TableBody: React.FC<TableBodyProps> = ({ entry }) => {
  const { suggestedPrimaryKeyword, suggestedSecondaryKeyword, suggestedAlternativeKeyword } = entry;
  return (
    <Body>
      <tr>
        <th>
          <Text tagName="p">Primary</Text>
        </th>
        <td>
          <Text tagName="p">{suggestedPrimaryKeyword}</Text>
        </td>
      </tr>
      <tr>
        <th>
          <Text tagName="p">Secondary</Text>
        </th>
        <td>
          <Text tagName="p">{suggestedSecondaryKeyword}</Text>
        </td>
      </tr>
      <tr>
        <th>
          <Text tagName="p">Alternative</Text>
        </th>
        <td>
          <Text>{suggestedAlternativeKeyword}</Text>
        </td>
      </tr>
    </Body>
  );
};
