import { Context as FeedbackContext, FeedbackEvent } from 'hooks/contexts/FeedbackContext';
import Hyperlink from '@ingka/hyperlink';
import Text from '@ingka/text';
import { useContext } from 'react';

const getFeedbackEventName = (event: FeedbackEvent) => {
  switch (event) {
    case FeedbackEvent.RULE_ADDED:
      return 'Add Rule';
    case FeedbackEvent.UPDATE_META_DATA:
      return 'Update Meta Data';
    default:
      return 'Optimera';
  }
};

export const FeedbackHeadline: React.FC = () => {
  const {
    state: { feedbackEvent },
  } = useContext(FeedbackContext);
  return feedbackEvent ? (
    <Text>How was your experience using {getFeedbackEventName(feedbackEvent)}</Text>
  ) : (
    <>
      <Text>How is your experience using Optimera?</Text>
      <Text>Your feedback help us better understand if we are creating value and where we need to improve.</Text>
      <Text>
        If you wish to report a bug, please refer to this{' '}
        <Hyperlink newWindow text={'Jira portal'} url={'https://jira.digital.ingka.com/servicedesk/customer/portal/16/create/5039?q=optimera'} />.
      </Text>
    </>
  );
};
