import { useFilter } from './contexts/FilterContext';

type PaginationOptionsItems = {
  totalItems: number;
  itemsPerPage: number;
};

type PaginationOptionsPages = {
  totalPages?: number;
};

export type PaginationOptions = PaginationOptionsItems &
  PaginationOptionsPages & {
    onPageChange?: (page: number) => void;
  };

export interface Paginator {
  page: number;
  lastPage: number;
  paginateFirstPage: () => void;
  paginatePreviousPage: () => void;
  paginateNextPage: () => void;
  paginateLastPage: () => void;
  paginatePage: (page: number) => void;
}

export const usePaginator = (options: PaginationOptions): Paginator => {
  const {
    filter: { page: currentPage },
    setFilter,
  } = useFilter();
  const firstPage = 1;
  const lastPage = getLastPage(options);

  const setCurrentPage = (page: number) => setFilter((prev) => ({ ...prev, page }));
  const paginateFirstPage = () => {
    if (currentPage !== firstPage) {
      paginate(firstPage);
    }
  };

  const paginatePreviousPage = () => {
    if (currentPage > firstPage) {
      const nextPage = currentPage - 1;
      paginate(nextPage);
    }
  };

  const paginateNextPage = () => {
    if (currentPage < lastPage) {
      const nextPage = currentPage + 1;
      paginate(nextPage);
    }
  };

  const paginateLastPage = () => {
    if (currentPage !== lastPage) {
      paginate(lastPage);
    }
  };

  const paginatePage = (page: number) => {
    if (page >= firstPage && page <= lastPage && currentPage !== page) {
      paginate(page);
    }
  };

  const paginate = (page: number) => {
    setCurrentPage(page);
    options?.onPageChange && options.onPageChange(page);
  };

  return {
    page: currentPage,
    lastPage,
    paginateFirstPage,
    paginatePreviousPage,
    paginateNextPage,
    paginateLastPage,
    paginatePage,
  };
};

const getLastPage = (options: PaginationOptions) => {
  if ('totalPages' in options && !!options.totalPages) {
    return options.totalPages;
  } else {
    return Math.ceil(options.totalItems / options.itemsPerPage);
  }
};
