import React, { FC } from 'react';
import styled from '@emotion/styled';
import { thicknessThin } from '@ingka/variables';
import { colourStaticGrey } from '@ingka/variables/colours-css';

interface DividerProps extends React.HTMLAttributes<HTMLHRElement> {
  vertical?: boolean;
}

const StyledDivider = styled.hr`
  border-block-start: ${thicknessThin} solid ${colourStaticGrey};
  margin: 0;
  height: 100%;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Divider: FC<DividerProps> = ({ vertical }) => {
  return vertical ? (
    <StyledDiv>
      <StyledDivider />
    </StyledDiv>
  ) : (
    <hr className="divider" />
  );
};
