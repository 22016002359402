import React, { useEffect, useState } from 'react';
import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';

interface PublishButtonProps {
  buttonText: string;
  disabled: boolean;
  onClick: () => void;
  isLoading: boolean;
  errorMessage?: string;
}

export const InfoModalButton: React.FC<PublishButtonProps> = ({ onClick, buttonText, isLoading, errorMessage, disabled }: PublishButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('Inserting pages');
  const [text, setText] = useState(
    <>
      <p>Please wait while the filtered pages are being inserted...</p>
      <Loading text="">
        <LoadingBall />
      </Loading>
    </>,
  );

  useEffect(() => {
    if (!isLoading && !modalOpen) {
      return;
    }
    if (!errorMessage) {
      setTitle('Insert complete');
      setText(<p>Finished successfully</p>);
      return;
    }
    if (errorMessage.includes('Received status code 400')) {
      setTitle('Insert failed');
      setText(<p>The text fields are filled incorrectly, please review and try again.</p>);
      return;
    }
    setTitle('Something went wrong');
    setText(<p>{errorMessage}</p>);
    return;
  }, [isLoading, errorMessage]);

  return (
    <>
      <Button
        text={buttonText}
        disabled={disabled}
        size={'small'}
        type="primary"
        data-cy="publish-button"
        onClick={() => {
          setModalOpen(true);
          onClick();
        }}
      />
      <Modal visible={modalOpen} data-testid={'publish-modal'} handleCloseBtn={() => setModalOpen(false)}>
        <Prompt
          titleId={title}
          title={title}
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button data-testid="publish-ok" text="OK" type="primary" size="small" />
            </ModalFooter>
          }
        >
          {text}
        </Prompt>
      </Modal>
    </>
  );
};
