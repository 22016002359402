import React, { ReactElement } from 'react';
import Hyperlink from '@ingka/hyperlink';
import SSRIcon from '@ingka/ssr-icon';
import WarningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import { ValueKeys } from 'api/graphql/fragments';
import { EditorCheckboxInput } from 'components/common/EditorCheckboxInput';
import { EditorTextAreaInput } from 'components/common/EditorTextAreaInput';
import { EditorTextInput } from 'components/common/EditorTextInput';
import { OmitTN } from 'opt-util/types';
import { MetaDataExtendedFragment } from '__generated__/types';
import { CanonicalAutosuggest } from './CanonicalAutosuggest';
import * as Styled from './styles';

interface Config {
  label: string;
  key: ValueKeys | 'descriptionIsSynced' | keyof OmitTN<MetaDataExtendedFragment>['mdToHtml'];
  showInEditor: boolean;
  showInPreview: boolean;
  component: typeof EditorTextAreaInput | typeof EditorTextInput | typeof EditorCheckboxInput | typeof CanonicalAutosuggest;
  disabled?: boolean;
  maxLength?: number;
  showLength?: boolean;
  showInSideBar?: boolean;
  tooltipMessage?: JSX.Element;
  translationButton?: boolean;
  fieldHelper?: ReactElement;
  fullWidth?: boolean;
  ariaLabelledby?: string;
}

interface MenuItem {
  label: string;
  id: string;
  showInSideBar: boolean;
  renderInVersionTypes?: string[];
}

const FieldHelper = () => {
  return (
    <Styled.FieldHelper>
      <SSRIcon paths={WarningTriangle} color={'#F26A2F'} />
      <p>Changing the URL slug updates the URL slugs for some existing filter pages under this category.</p>
    </Styled.FieldHelper>
  );
};

const MDToolTip = () => (
  <>
    Markdown format is expected, see <Hyperlink text="reference" url="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" newWindow />.
  </>
);

const plpConfig: Config[] = [
  { label: 'Id', key: 'id', component: EditorTextInput, showInEditor: false, showInPreview: true, disabled: true },
  {
    label: 'URL Slug',
    key: 'urlSlug',
    component: EditorTextInput,
    showInEditor: true,
    showInPreview: true,
    fieldHelper: <FieldHelper />,
  },
  { label: 'Page Title', key: 'pageTitle', component: EditorTextInput, maxLength: 60, showLength: true, showInEditor: true, showInPreview: true },
  {
    label: 'Description',
    key: 'description',
    component: EditorTextAreaInput,
    maxLength: 150,
    showLength: true,
    showInEditor: true,
    showInPreview: true,
  },
  { label: 'OG Title', key: 'ogTitle', component: EditorTextInput, showInEditor: true, showInPreview: true },
  {
    label: 'OG Description',
    key: 'ogDescription',
    component: EditorTextAreaInput,
    maxLength: 150,
    showLength: true,
    showInEditor: true,
    showInPreview: true,
  },
  { label: 'OG Image', key: 'ogImage', component: EditorTextInput, showInEditor: true, showInPreview: true },
  {
    label: 'Sync OG Title and Description',
    key: 'descriptionIsSynced',
    component: EditorCheckboxInput,
    showInEditor: false,
    showInPreview: false,
  },
  { label: 'Keywords', key: 'keywords', component: EditorTextInput, showInEditor: true, showInPreview: true },
  { label: 'Robots', key: 'robots', component: EditorTextInput, showInEditor: true, showInPreview: true },
  { label: 'Canonical ID', key: 'canonicalId', component: CanonicalAutosuggest, showInEditor: true, showInPreview: true },
];
const pipConfig: Config[] = [
  { label: 'Id', key: 'id', component: EditorTextInput, showInEditor: false, showInPreview: true, disabled: true },
  { label: 'URL Slug', key: 'urlSlug', component: EditorTextInput, showInEditor: true, showInPreview: true },
  { label: 'Page Title', key: 'pageTitle', component: EditorTextInput, maxLength: 60, showLength: true, showInEditor: true, showInPreview: true },
  {
    label: 'Description',
    key: 'description',
    component: EditorTextAreaInput,
    maxLength: 150,
    showLength: true,
    showInEditor: true,
    showInPreview: true,
  },
  { label: 'OG Title', key: 'ogTitle', component: EditorTextInput, showInEditor: true, showInPreview: true },
  {
    label: 'OG Description',
    key: 'ogDescription',
    component: EditorTextAreaInput,
    maxLength: 150,
    showLength: true,
    showInEditor: true,
    showInPreview: true,
  },
  { label: 'OG Image', key: 'ogImage', component: EditorTextInput, showInEditor: true, showInPreview: true },
  { label: 'Keywords', key: 'keywords', component: EditorTextInput, showInEditor: true, showInPreview: true },
  { label: 'Robots', key: 'robots', component: EditorTextInput, showInEditor: true, showInPreview: true },
  { label: 'Canonical ID', key: 'canonicalId', component: CanonicalAutosuggest, showInEditor: true, showInPreview: true },
];
const filterPageConfig: Config[] = [
  { label: 'URL Slug', key: 'urlSlug', component: EditorTextInput, showInEditor: true, showInPreview: true },
  { label: 'Canonical ID', key: 'canonicalId', component: CanonicalAutosuggest, showInEditor: true, showInPreview: false },
  { label: 'Keywords', key: 'keywords', component: EditorTextInput, showInEditor: true, showInPreview: true },
  { label: 'Robots', key: 'robots', component: EditorTextInput, showInEditor: true, showInPreview: false },
  { label: 'OG Image', key: 'ogImage', component: EditorTextInput, showInEditor: true, showInPreview: false, fullWidth: true },
  { label: 'URL', key: 'canonical', component: EditorTextInput, showInEditor: true, showInPreview: true, disabled: true, fullWidth: true },
  { label: 'H1', key: 'h1', component: EditorTextInput, showInEditor: false, showInPreview: true },
  { label: 'Filter Type', key: 'filterType', component: EditorTextInput, showInEditor: false, showInPreview: true, disabled: true },
  { label: 'Filter Name', key: 'filterName', component: EditorTextInput, showInEditor: false, showInPreview: true, disabled: true },
  { label: 'Page Title', key: 'pageTitle', component: EditorTextInput, maxLength: 60, showLength: true, showInEditor: true, showInPreview: false },
  { label: 'OG Title', key: 'ogTitle', component: EditorTextInput, showInEditor: true, showInPreview: false },
  { label: 'Global ID', key: 'id', component: EditorTextInput, showInEditor: false, showInPreview: true, disabled: true },
  {
    label: 'Description',
    key: 'description',
    component: EditorTextAreaInput,
    maxLength: 150,
    showLength: true,
    showInEditor: true,
    showInPreview: false,
  },
  {
    label: 'OG Description',
    key: 'ogDescription',
    component: EditorTextAreaInput,
    maxLength: 150,
    showLength: false,
    showInEditor: true,
    showInPreview: false,
  },
  {
    label: 'Sync OG Title and Description',
    key: 'descriptionIsSynced',
    component: EditorCheckboxInput,
    showInEditor: false,
    showInPreview: false,
  },
  { label: 'Published', key: 'isOptimized', component: EditorCheckboxInput, showInEditor: false, showInPreview: false },
  {
    label: 'Intro Text',
    key: 'introText',
    component: EditorTextAreaInput,
    showInEditor: false,
    showInPreview: true,
    tooltipMessage: <MDToolTip />,
    translationButton: true,
  },
  {
    label: 'Body Copy',
    key: 'bodyCopy',
    component: EditorTextAreaInput,
    showInEditor: false,
    showInPreview: true,
    tooltipMessage: <MDToolTip />,
    translationButton: true,
  },
];

export const editSheetSideMenuItems: MenuItem[] = [
  { label: 'Meta Data', id: 'metaData', showInSideBar: true },
  { label: 'Copy', id: 'copy', showInSideBar: true, renderInVersionTypes: ['filter'] },
];

export { plpConfig, pipConfig, filterPageConfig };
export type { Config };
