import { Dispatch } from 'react';
import { Action, createDataContext } from './createDataContext';
import { getStorageValue, setStorageValue } from 'hooks/useLocalStorage';

export interface FeedbackStorage {
  timestamp: string;
}

export interface State {
  isOpen: boolean;
  feedbackEvent?: FeedbackEvent | null;
}

export enum ActionType {
  SET_IS_OPEN = 'set_is_open',
  SET_OPEN_ON_EVENT = 'set_open_on_event',
}

export enum FeedbackEvent {
  RULE_ADDED = 'rule_added',
  UPDATE_META_DATA = 'update_meta_data',
  UPLOAD_META_DATA = 'upload_meta_data',
}

const feedbackReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_IS_OPEN:
      return { ...state, isOpen: action.payload, feedbackEvent: null };
    case ActionType.SET_OPEN_ON_EVENT:
      return { ...state, isOpen: true, feedbackEvent: action.payload };
    default:
      return state;
  }
};

export const shouldTriggerFeedback = (): boolean => {
  const today = new Date();
  const feedbackTimestamp = getStorageValue<FeedbackStorage>('feedback-timestamp');
  const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30)).toISOString();

  if (feedbackTimestamp?.timestamp && feedbackTimestamp.timestamp > thirtyDaysAgo) {
    return false;
  }

  setStorageValue<FeedbackStorage>('feedback-timestamp', { timestamp: today.toISOString() });
  return true;
};

const setIsOpen = (dispatch: Dispatch<Action>) => (shouldBeOpen: boolean) => {
  dispatch({ type: ActionType.SET_IS_OPEN, payload: shouldBeOpen });
};

const setOpenOnEvent = (dispatch: Dispatch<Action>) => (event: FeedbackEvent) => {
  if (shouldTriggerFeedback()) {
    dispatch({ type: ActionType.SET_OPEN_ON_EVENT, payload: event });
  }
};

export const { Provider, Context } = createDataContext(feedbackReducer, { setIsOpen, setOpenOnEvent }, { isOpen: false });
