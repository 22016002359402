import React, { useCallback, useContext } from 'react';
import Button from '@ingka/button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import { is, pathOr } from 'ramda';
import { Label } from 'components/common/ComparisonTable';
import { DeleteButton } from 'components/common/DeleteButton';
import { Column, RenderFunction } from 'components/common/Table';
import { FlexContainer } from 'components/FlexContainer';
import { GET_UPLOAD_BY_ID } from 'components/seo/queries';
import { EditSheet } from 'features/MetaData/EditSheet';
import { Provider as EditorProvider } from 'hooks/contexts/EditorContext';
import { Context as MetaDataContext, Provider as MetaDataProvider } from 'hooks/contexts/MetaDataContext';
import { emptyOrNil, propValue } from 'opt-util/helpers';
import { MetadataProps } from 'types';
import {
  MetaDataEntry,
  MetaDataEntryInput,
  UploadRowComparison,
  useDeleteUploadItemMutation,
  useEditItemMutation,
  useGetLatestVersionEntriesQuery,
} from '__generated__/types';
import { Entry, Original, Pill, Row, Updated, VerticalAlign } from './styles';
import { toMetaData } from '../../../opt-util/toGenericMetadata';

export interface IComparisonRow {
  original?: MetaDataEntry | null;
  new: UploadRowComparison;
}

interface ComparisonTableCellProps {
  label: string;
  render?: RenderFunction;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  originalValue: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  newValue: any;
  rowData: IComparisonRow;
}

const ComparisonTableCell = (props: ComparisonTableCellProps) => {
  const showSingleValue = props.originalValue === props.newValue || emptyOrNil(props.originalValue) || props.newValue === null;
  const singleValue = emptyOrNil(props.originalValue) ? props.newValue : props.originalValue;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const renderSingleValue = (val: any) => {
    return props.render && is(Function, props.render) ? props.render(val, props.label, props.rowData) : val;
  };
  return (
    <Entry data-title={props.label}>
      <Label>{props.label}</Label>
      <span>
        {showSingleValue ? (
          renderSingleValue(singleValue)
        ) : (
          <VerticalAlign>
            <Original>{renderSingleValue(props.originalValue)}</Original>
            <Updated>
              <Pill>new</Pill>
              {renderSingleValue(props.newValue)}
            </Updated>
          </VerticalAlign>
        )}
      </span>
    </Entry>
  );
};

interface ComparisonRowProps {
  uploadId: string;
  columns: Column[];
  rowData: IComparisonRow;
  options: MetadataProps;
}

const View: React.FC<ComparisonRowProps> = ({ columns, rowData }) => {
  const hasData = (column: Column, newData: UploadRowComparison, original?: MetaDataEntry | null): boolean => {
    return (original && !!propValue(column.key)(original)) || !!propValue(column.key)(newData);
  };
  return (
    <FlexContainer direction={'column'} grow={1}>
      {columns.map(
        (column, index) =>
          hasData(column, rowData.new, rowData.original) && (
            <ComparisonTableCell
              key={index}
              label={column.label}
              render={column.render}
              originalValue={propValue(column.key)(rowData.original)}
              newValue={propValue(column.key)(rowData.new)}
              rowData={rowData}
            />
          ),
      )}
    </FlexContainer>
  );
};

const ComparisonRow = (props: ComparisonRowProps) => {
  const uploadByIdQuery = { query: GET_UPLOAD_BY_ID, variables: { id: props.uploadId } };
  const { setIsEditorOpen } = useContext(MetaDataContext);
  const [editItem] = useEditItemMutation({
    refetchQueries: [uploadByIdQuery],
  });
  const [deleteItem] = useDeleteUploadItemMutation({
    refetchQueries: [uploadByIdQuery],
    variables: { metaDataId: props.rowData.new.id, uploadId: props.uploadId },
  });
  const { data } = useGetLatestVersionEntriesQuery({
    variables: {
      country: props.options.country,
      language: props.options.language,
      type: props.options.type,
    },
  });

  const editItemCallback = useCallback(
    (entry: MetaDataEntryInput) => {
      const item = toMetaData(entry);
      return editItem({
        variables: {
          versionId: props.uploadId,
          entry: { ...item, pageTitle: item.pageTitle || '' },
        },
      });
    },
    [props.uploadId],
  );
  const testid = pathOr('', ['rowData', 'new', 'id'], props);
  const currentlyEditedEntry = (() => {
    const descriptionIsSynced = props.rowData.new.description === props.rowData.new.ogDescription;
    const entry = {
      ...props.rowData.new,
      isOptimized: true,
      needsReview: false,
      hasProducts: false,
      searchConsoleData: null,
      descriptionIsSynced,
      mdToHtml: null,
    };
    const version = data?.latestVersion;
    if (!version) return;
    const versionDetails = {
      type: version.type,
      urlSlugSyncEnabled: false,
      versionId: version._id,
      market: { country: props.options.country, language: props.options.language },
    };
    return {
      formData: entry,
      hasErrors: false,
      errors: {},
      versionDetails,
      isOpen: true,
    };
  })();

  return (
    <Row data-testid={`metadata-${testid}`}>
      <View {...props} />
      <FlexContainer direction={'row'} align={'center'}>
        {currentlyEditedEntry && (
          <EditorProvider injectedState={currentlyEditedEntry}>
            <EditSheet editItem={editItemCallback} />
          </EditorProvider>
        )}
        <Button
          text={'Edit'}
          ssrIcon={pencil}
          type={'tertiary'}
          iconOnly={true}
          iconPosition={'leading'}
          onClick={() => setIsEditorOpen(props.rowData.new.id)}
          data-cy={'edit-button'}
        />
      </FlexContainer>
      <DeleteButton deleteItem={deleteItem} />
    </Row>
  );
};

interface ComparisonTableProps {
  data: IComparisonRow[];
  columns: Column[];
  striped?: boolean;
  options: MetadataProps;
  uploadId: string;
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({ data, columns, options, uploadId }) => {
  return (
    <MetaDataProvider>
      <VerticalAlign>
        {data.map((val, i) => {
          return <ComparisonRow key={`comparison-row-${i}`} columns={columns} rowData={val} options={options} uploadId={uploadId} />;
        })}
      </VerticalAlign>
    </MetaDataProvider>
  );
};

export default ComparisonTable;
