import { request } from 'gaxios';
import { Feature } from 'hooks/contexts/FeatureContext';

const optimeraService = '/api/optimera';

export const getFeatures = async (token: string): Promise<Feature[]> => {
  try {
    return await request({
      method: 'GET',
      url: `${optimeraService}/feature/active`,
      headers: { Authorization: `Bearer ${token}` },
    }).then((a) => a.data as Feature[]);
  } catch (error) {}
  return [];
};
