import styled from '@emotion/styled';
import { space100, space75 } from '@ingka/variables';

export const CheckboxGroup = styled.div`
  padding: 20px;
  gap: ${space100};
  display: grid;

  .checkbox__label {
    font-size: 12px;
  }
`;
export const CheckboxGrid = styled.div`
  display: grid;
  row-gap: ${space75};
  column-gap: ${space100};
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    '. .'
    '. .'
    '. .'
    '. .';
`;
export const ButtonFooter = styled.div`
  display: flex;
  width: 255px;
  padding-top: 16px;

  .inner_padding_over_ride {
    padding-right: 16px;
  }

  .inner_padding_over_ride > .btn__inner {
    padding: 0 1rem;
  }

  button:last-child {
    margin-left: auto;
  }
`;
