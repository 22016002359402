import React from 'react';
import { BlueLabel } from 'components/common/styles';
import { SearchDataItem, SearchDataLabel, SearchDataWrapper } from './styles';

interface SearchConsoleDataProps {
  avg_position: number;
  search_clicks: number;
  search_ctr: string;
  search_impressions: number;
}
export const SearchConsoleData: React.FC<{ searchConsoleData: SearchConsoleDataProps | null }> = ({ searchConsoleData }) => {
  if (!searchConsoleData) {
    return null;
  }
  return (
    <SearchDataWrapper>
      <BlueLabel>SEO metrics</BlueLabel>
      <SearchDataItem data-testid="search_clicks">
        <SearchDataLabel>Clicks</SearchDataLabel>
        <span>{searchConsoleData.search_clicks}</span>
      </SearchDataItem>
      <SearchDataItem data-testid="search_ctr">
        <SearchDataLabel>CTR</SearchDataLabel>
        <span>{searchConsoleData.search_ctr}</span>
      </SearchDataItem>
      <SearchDataItem data-testid="search_impressions">
        <SearchDataLabel>Impressions</SearchDataLabel>
        <span>{searchConsoleData.search_impressions}</span>
      </SearchDataItem>
      <SearchDataItem data-testid="avg_position">
        <SearchDataLabel>Average position</SearchDataLabel>
        <span>{searchConsoleData.avg_position}</span>
      </SearchDataItem>
    </SearchDataWrapper>
  );
};
