import { Column } from '../../types';

export const generateColumnToCheckBoxesOptions = (column: Column[]) =>
  column
    .map(({ label, value, visible }) => ({
      checked: visible,
      label,
      value,
      id: value,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
