import styled from '@emotion/styled';
import { ModalFooter, Prompt } from '@ingka/modal';
import Text from '@ingka/text';
import { Dropdown } from 'components/Dropdown';
import { FlexContainer } from 'components/FlexContainer';
import Button from '@ingka/button';
import { fontSize50, space100, space125, space25, space250 } from '@ingka/variables';

export const FilterDropdown = styled(Dropdown)`
  width: 100%;

  & select {
    font-size: ${fontSize50};
    height: 36px;
    padding: ${space25} ${space25};
  }
`;

export const ButtonFooter = styled(ModalFooter)`
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding-top: ${space100};

  .btn {
    width: auto;
    flex-grow: unset;

    .btn__inner {
      min-height: ${space250};
    }

    &:nth-of-type(2) {
      margin-left: auto;
    }
  }
`;

export const PromptWithWidth = styled(Prompt)`
  .prompt__content-wrapper {
    width: 100%;
    max-width: 1200px;
  }
`;

export const ValidityFilterWrapper = styled.div<{ columns: number }>`
  height: 100%;
  margin-bottom: ${space125};
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  gap: ${space125};

  > fieldset,
  > div {
    width: 100%;
    padding-right: ${space100};
  }
`;

export const ClearAllFilters = styled(Text)`
  margin-left: ${space25};
  cursor: pointer;
  color: rgb(37, 76, 150);

  &:hover {
    text-decoration: underline;
  }
`;

export const ValidityFilterDropdown = styled(FlexContainer)`
  margin-top: ${space125};
  width: 100%;
`;

export const ValidityFilterCheckboxWrapper = styled.div`
  margin-top: ${space125};
  width: 100%;
`;

export const ActiveFiltersRowContainer = styled(FlexContainer)`
  margin-bottom: ${space125};
`;

export const ButtonFilter = styled(Button)`
  width: fit-content;
`;
