import { test } from 'ramda';
import { isFutureDate } from 'opt-util/helpers';

export enum ValidationTypes {
  url,
  date,
  httpStatus,
  path,
  pathExist,
  pathShort,
  pathGlobal,
}

const INCORRECT_DATE = 'Date should be in format YYYY-MM-DD';
const dateValidation = (value: string | undefined): string =>
  !value || !test(/^2[0-9]{3}-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/, value) ? INCORRECT_DATE : '';

const globalRedirectMatchPathRegex = /^[^\/]|(\/([a-zA-Z0-9]{2}\/)[a-zA-Z]{2}\/?)|(.?\?.*)$/;
const globalRedirectTargetPathRegex = /^[^\/]|(\/([a-zA-Z0-9]{2}\/)[a-zA-Z]{2}\/?)|(.?\?.*)$/;
const urlValidation = (value: string, global?: boolean) => {
  switch (true) {
    case !global && !value.match(/^(https):\/\/[^ "]+$/):
      return 'Incorrect URL format';
    case global && !!value.match(globalRedirectTargetPathRegex):
      return 'Incorrect URL format; Should not contain search queries or country/language';
    default:
      return '';
  }
};

const validatePathWithCountryAndLanguage = (
  value: string,
  country?: string,
  language?: string,
): {
  isCorrectPath: boolean;
  market: string;
} => {
  const validPath = new RegExp(`^/(${country}/${language})/`);
  const market = `/${country}/${language}/`;
  return { isCorrectPath: !!value.match(validPath), market };
};

const joinMarkets = (markets: string[]): string => {
  return markets.slice(0, markets.length - 1).join(', ') + ' or ' + markets[markets.length - 1];
};

const pathValidation = (value: string, country?: string, languages?: string[]) => {
  const markets: string[] = [];
  for (const language of languages || []) {
    const { isCorrectPath, market } = validatePathWithCountryAndLanguage(value, country, language);
    if (!isCorrectPath) markets.push(market);
  }
  switch (true) {
    case markets.length > 1:
      return `Incorrect URL path; Should start with ${joinMarkets(markets)}`;
    case markets.length === 1 && languages?.length === 1:
      return `Incorrect URL path; Should start with ${markets}`;
    case !!value.match(/\?/):
      return 'Incorrect URL path; Search queries not allowed';
    default:
      return '';
  }
};

const pathShortValidation = (value: string) => {
  const validPath = new RegExp('^\\/[\\p{L}\\p{N}\\p{P}\\p{M}%-/]+$', 'u');
  switch (true) {
    case !value.match(validPath):
      return 'Incorrect URL path; Should be /example-path';
    default:
      return '';
  }
};

const pathGlobalRedirectValidation = (value: string) => {
  if (value.match(globalRedirectMatchPathRegex)) {
    return 'Incorrect URL path; Path should not start with /country/language/some-path/ since redirect applies to all markets. Path should be /some-path/';
  }
  return '';
};

const pathExistValidation = (value: string) => {
  switch (value) {
    case 'pathExist':
      return 'The path already exists in another rule.';
    case 'pathExistInTarget':
      return 'The path is already used as a redirect URL in another rule.';
    case 'pathExistInGlobalSiteStructure':
      return "The path is part of the global site structure and can't be redirected.";
    default:
      return '';
  }
};
export const validateValueWithType = (
  value: string,
  type: ValidationTypes,
  country?: string,
  language?: string,
  scheduledDate?: string,
  languages?: string[],
): string => {
  let isScheduledRule = false;

  if (!value) {
    return '';
  }

  if (scheduledDate) {
    isScheduledRule = isFutureDate(scheduledDate);
  }
  switch (type) {
    case ValidationTypes.url:
      return urlValidation(value, language === 'global');

    case ValidationTypes.path:
      return pathValidation(value, country, languages);

    case ValidationTypes.pathShort:
      return pathShortValidation(value);

    case ValidationTypes.pathGlobal:
      return pathGlobalRedirectValidation(value);

    case ValidationTypes.pathExist:
      return pathExistValidation(value);

    case ValidationTypes.date:
      return dateValidation(value);

    case ValidationTypes.httpStatus:
      if (value.toLowerCase() === 'none') {
        return '';
      }
      if (String(value) !== '200' && !isScheduledRule) {
        return 'Please enter a URL that returns an HTTP status code of 200.';
      }
      if (String(value) === '404' && isScheduledRule) {
        return '';
      }

      return '';
  }
};

export const getValidationType = (policy: string): ValidationTypes => {
  const isShortURLPage = policy && policy.includes('.');
  if (isShortURLPage) return ValidationTypes.pathShort;
  if (policy?.includes('global')) return ValidationTypes.pathGlobal;
  return ValidationTypes.path;
};
