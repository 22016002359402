import styled from '@emotion/styled';
import { fontSize75, fontSize800, lineHeight500Long, space100, space75 } from '@ingka/variables';
import { FlexContainer } from 'components/FlexContainer';

export const StyledFlexContainer = styled(FlexContainer)`
  margin: 3.75rem auto;
  max-width: 560px;
`;

export const ErrorTitle = styled.h1`
  font-size: ${fontSize800};
  margin-bottom: ${space75};
`;

export const ErrorMessage = styled.p`
  font-size: ${fontSize75};
  line-height: ${lineHeight500Long};
  margin-bottom: ${space100};
`;

export const TextFlexContainer = styled(FlexContainer)`
  max-width: 490px;
`;

export const ErrorImage = styled.img`
  height: 270px;
  width: auto;
  margin-inline: auto;
`;
