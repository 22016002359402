import { useContext } from 'react';
import { Context as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import checkmarkCircleIcon from '@ingka/ssr-icon/paths/checkmark-circle';
import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';
import * as Styled from './styles';

export const SystemStatusMessage: React.FC = () => {
  const {
    state: { variant, title, bodyText, actions, isVisible },
    setStatusMessage,
  } = useContext(StatusMessageContext);
  const statusMessageIcon = { positive: checkmarkCircleIcon, cautionary: warningTriangleIcon };

  return isVisible ? (
    <Styled.StatusMessage
      ssrIcon={statusMessageIcon[variant]}
      variant={variant}
      title={title}
      body={bodyText}
      dismissable
      onDismissClick={() => {
        setStatusMessage({ isVisible: false });
      }}
      actions={actions}
    />
  ) : null;
};
