import { FC, useContext, useEffect } from 'react';
import Button from '@ingka/button';
import { Context as ErrorMessageContext } from '../../hooks/contexts/ErrorMessageContext';
import * as Styled from './styles';
import { space125 } from '@ingka/variables';
import { useHistory } from 'react-router-dom';

type ErrorProps = {
  errorMessage: string;
  errorTitle: string;
  errorAction: () => void;
  errorButtonText: string;
  hasPropsError: boolean;
};

export const ErrorComp: FC<Partial<ErrorProps>> = (props) => {
  const {
    state: { message, title, buttonText, action, hasError },
    setClearError,
  } = useContext(ErrorMessageContext);
  const history = useHistory();

  const defaultErrorContent = {
    title: 'Oops! Something went wrong',
    message: 'Please try again later',
    buttonText: 'Go Back',
    onClick: () => {
      history.replace('/');
    },
  };

  const errorMessage = message ?? props.errorMessage ?? defaultErrorContent.message;
  const errorTitle = title ?? props.errorTitle ?? defaultErrorContent.title;
  const errorButtonText = buttonText ?? props.errorButtonText ?? defaultErrorContent.buttonText;

  const onClickResetError = (): void => {
    setClearError();
    action && action();
    props.errorAction && props.errorAction();
    !action && !props.errorAction && defaultErrorContent.onClick();
  };

  useEffect(() => {
    if (hasError)
      return () => {
        setClearError();
      };
  }, [hasError]);
  if (!hasError && !props.hasPropsError) return null;
  return (
    <Styled.StyledFlexContainer data-testid={'error-container'} direction={'column'} gap={Number(space125)}>
      <Styled.ErrorImage src={'/error.svg'} alt={'error occurred'} />
      <Styled.TextFlexContainer direction={'column'} align={'flex-start'}>
        <Styled.ErrorTitle>{errorTitle}</Styled.ErrorTitle>
        <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>
        <Button text={errorButtonText} onClick={onClickResetError} size={'small'} />
      </Styled.TextFlexContainer>
    </Styled.StyledFlexContainer>
  );
};
