import React, { useContext, useEffect, useState } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import { Option } from '@ingka/select';
import Tabs, { Tab } from '@ingka/tabs';
import languages from 'iso-639-1';
import Container from 'components/common/Container';
import { DashboardTabPanel } from 'features/Dashboard/DashboardTabPanels';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { useCountry } from 'hooks/useCountry';
import { LatestByCountryQuery, useLatestByCountryQuery } from '__generated__/types';
import * as Styled from './styles';
import Text from '@ingka/text';
import { FlexContainer } from '../../components/FlexContainer';
import { RuleInvocations } from '../../features/Dashboard/RuleInvocations';

export type DashboardVersion = {
  id: string;
  type: string;
  languageCode: string;
  languageName: string;
  countryCode: string;
  size: number;
  needsReview: number;
  optimizedItems: number;
  triggerType?: string;
};
type Language = Pick<DashboardVersion, 'languageName' | 'languageCode' | 'countryCode'>;
type VersionsForCountry = LatestByCountryQuery['latestVersionsForCountry'];
type VersionForCountry = VersionsForCountry[0];
const formatVersion = (version: VersionForCountry): DashboardVersion => ({
  id: version._id,
  type: version.type.toLowerCase(),
  languageCode: version.language,
  languageName: languages.getName(version.language),
  countryCode: version.country,
  size: version.size,
  needsReview: version.needsReview,
  optimizedItems: version.optimizedItems,
});

const getSelectableLanguages = (versions: DashboardVersion[], countryCode: string): Language[] => {
  const uniqueLanguages = new Map<string, Language>();
  versions.forEach(({ languageCode, languageName }) => {
    if (!uniqueLanguages.has(languageCode)) {
      uniqueLanguages.set(languageCode, { languageCode, languageName, countryCode });
    }
  });
  return Array.from(uniqueLanguages.values());
};

const getSelectedVersionsForLanguage = (versions: DashboardVersion[], selectedLanguage: string): DashboardVersion[] => {
  if (selectedLanguage === '*') {
    const languageVersionMap = new Map<string, DashboardVersion>();
    versions.forEach((version) => {
      const prevVersion = languageVersionMap.get(version.type);
      if (!prevVersion) {
        languageVersionMap.set(version.type, version);
      } else {
        const aggregatedVersion: DashboardVersion = {
          ...prevVersion,
          size: prevVersion.size + version.size,
          optimizedItems: prevVersion.optimizedItems + version.optimizedItems,
          needsReview: prevVersion.needsReview + version.needsReview,
        };
        languageVersionMap.set(version.type, aggregatedVersion);
      }
    });
    return Array.from(languageVersionMap.values());
  }
  return versions.filter((version) => version.languageCode === selectedLanguage);
};

export const Dashboard: React.FC = () => {
  const [country] = useCountry();
  const [activeTabKey, setActiveTabKey] = useState('plp');
  const [activeRedirectUsageTabKey, setActiveRedirectUsageTabKey] = useState('high');
  const countryCode = country?.countryCode;
  const { setRefetchAfter } = useContext(RulesContext);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('*');
  const { data, loading } = useLatestByCountryQuery({
    variables: { country: countryCode as string },
    skip: !countryCode,
  });
  const versionsForCountry = data?.latestVersionsForCountry;
  const pageTypesMap = { plp: 'PLP', redirect: 'Redirect', filter: 'Filter', shorturl: 'Short URL', pip: 'PIP' };
  const formattedVersions = versionsForCountry?.map(formatVersion) ?? [];
  const selectableLanguages = getSelectableLanguages(formattedVersions, countryCode ? countryCode : '');
  const selectedVersions = getSelectedVersionsForLanguage(formattedVersions, selectedLanguage === '*' ? '*' : selectedLanguage.substring(3, 5));
  const multiplePolicies = selectableLanguages.map(({ languageCode }) => `${countryCode}-${languageCode}`);
  const ruleUsageTabs = [
    { type: 'high', name: 'High' },
    { type: 'low', name: 'Low' },
  ];

  if (loading) {
    return (
      <Container>
        <Loading text={'Loading...'}>
          <LoadingBall />
        </Loading>
      </Container>
    );
  }

  return (
    <Container>
      <Styled.DashboardHeaderWrapper>
        <h2>Overview {selectedLanguage !== '*' && `- ${selectedLanguage.toUpperCase()}`}</h2>
        <Styled.LanguageDropdown
          id="languageSelector"
          data-testid={'dashboard-language-select'}
          onChange={(e) => {
            setSelectedLanguage(e.target.value);
            setRefetchAfter(`Dashboard-${e.target.value}`);
          }}
          value={selectedLanguage}
        >
          <Option value={'*'} name={'All Languages'} />
          {selectableLanguages?.map(({ languageCode, languageName, countryCode }) => (
            <Option key={languageCode} value={`${countryCode}-${languageCode}`} name={languageName} />
          ))}
        </Styled.LanguageDropdown>
      </Styled.DashboardHeaderWrapper>
      <Styled.DashboardSectionWrapper direction={'column'}>
        <Styled.DashboardSection direction="column">
          <Text tagName={'h4'} headingSize={'xs'}>
            Status overview
          </Text>
          <FlexContainer direction={'column'}>
            <Tabs
              tabs={Object.entries(pageTypesMap).map(([type, label]) => (
                <Tab key={type} tabPanelId={type} text={label} />
              ))}
              tabPanels={<DashboardTabPanel activeTab={activeTabKey} selectedVersions={selectedVersions} selectedLanguage={selectedLanguage} />}
              defaultActiveTab={activeTabKey}
              onTabChanged={(selectedTabKey) => {
                setActiveTabKey(selectedTabKey);
              }}
            />
          </FlexContainer>
        </Styled.DashboardSection>
        <Styled.DashboardSection direction="column">
          <Text tagName={'h4'} headingSize={'xs'}>
            Redirect Usage
          </Text>
          <FlexContainer direction={'column'} mt={0.5}>
            <Tabs
              tabs={ruleUsageTabs.map(({ type, name }) => (
                <Tab key={type} tabPanelId={type} text={name} />
              ))}
              tabPanels={
                <RuleInvocations
                  policy={selectedLanguage}
                  direction={activeRedirectUsageTabKey === 'high' ? 'desc' : 'asc'}
                  multiplePolicies={selectedLanguage === '*' ? multiplePolicies : undefined}
                />
              }
              defaultActiveTab={activeRedirectUsageTabKey}
              onTabChanged={(selectedKey: string) => setActiveRedirectUsageTabKey(selectedKey)}
            />
          </FlexContainer>
        </Styled.DashboardSection>
      </Styled.DashboardSectionWrapper>
    </Container>
  );
};
