import styled from '@emotion/styled';
interface FlexProps {
  direction?: 'row' | 'column';
  justify?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'space-evenly';
  align?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end' | 'stretch';
  grow?: 1 | 0;
  margin?: number;
  flexWrap?: 'wrap' | 'nowrap';
  gap?: number;
  pt?: number;
  mt?: number;
  mr?: number;
  p?: number;
  flex?: 1 | 0;
}
export const FlexContainer = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'stretch'};
  flex-grow: ${(props) => props.grow || 0};
  margin: ${(props) => props.margin || 0}em;
  margin-top: ${(props) => props.mt || 0}em;
  margin-right: ${(props) => props.mr || 0}em;
  gap: ${(props) => props.gap || 0}em;
  padding-top: ${(props) => props.pt || 0}em;
  padding: ${(props) => props.p || 0}em;
  flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
`;
