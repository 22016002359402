import React, { ChangeEvent } from 'react';
import Checkbox from '@ingka/checkbox';
import FormField from '@ingka/form-field';

interface CheckboxInputProps {
  disabled?: boolean;
  label: string;
  shouldValidate?: boolean;
  errorMessage?: string;
  id: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({ id, disabled, label, shouldValidate, errorMessage, checked, onChange }) => {
  return (
    <FormField
      validation={{
        msg: errorMessage,
        id: `${id}-error-message`,
      }}
      valid={!errorMessage}
      shouldValidate={shouldValidate}
      disabled={disabled}
    >
      <Checkbox disabled={disabled} id={id} name={id} label={label} onChange={onChange} value={`${checked}`} checked={checked} />
    </FormField>
  );
};
