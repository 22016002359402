import styled from '@emotion/styled';
import TextArea from '@ingka/text-area';
import { space200, space50, space75 } from '@ingka/variables';

export const MenuList = styled.ul`
  padding-top: 1rem;
`;

export const MenuItem = styled.li`
  list-style: inside;
  padding: 0.2rem 0;
`;

export const UploadSuccess = styled.h4`
  color: #0a8a00;
  padding-bottom: 1rem;
`;

export const FeedbackButtonContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
`;

export const SurveyTextArea = styled(TextArea)`
  margin-top: ${space50};
  margin-bottom: ${space75};

  label > p {
    height: 1.5rem;
    text-align: center;
    color: #484848 !important;
  }

  textarea {
    resize: none;
  }
`;

export const ConfirmationMessage = styled.h3`
  text-align: center;
  padding: ${space200} 0;
`;

export const CountriesTable = styled.div`
  overflow: auto;
  width: 50vw;
  max-height: 60vh;
  margin-top: 20px;
  padding: 10px;
`;

export const SurveyRatingContainer = styled.div`
  padding: ${space50} 0;
`;

export const SurveyRatingTip = styled.div`
  margin-top: ${space50};
`;
