export const findCorrectPolicy = (policy: string, matchUrl: string, isR1Global: boolean, countryCode: string | undefined): string => {
  const langSelected = policy !== '*' && policy !== 'multiple';
  if (policy && langSelected) return policy;
  else if (!isR1Global && countryCode === 'r1') return 'r1-en';
  else if (isR1Global) return 'r1-global';
  else {
    const IKEA_URL = /^https:\/\/\www.ikea.com/;
    const path = IKEA_URL.test(matchUrl) ? matchUrl.replace(IKEA_URL, '') : matchUrl;
    return path.split('/').slice(1, 3).join('-');
  }
};

export const getSelectedPolicy = (currentCountry: string, matchURL: string): string => {
  const matchCountryRegex = new RegExp(`^\/${currentCountry}\/[a-zA-Z]*\/`);
  if (matchURL.match(matchCountryRegex)) {
    return matchURL.split('/').slice(1, 3).join('-');
  } else return '';
};
