import React from 'react';
import { Tooltip } from 'components/common/Tooltip';

const targetStatusTooltipText = (
  <ul>
    <li>
      <strong>200 OK:</strong> Everything is good! The website or webpage you’re trying to access is available and working properly.
    </li>
    <li>
      <strong>404 Not Found:</strong> Uh-oh, the website or webpage you’re trying to access can’t be found. It’s like going to a store and not being
      able to find what you’re looking for.
    </li>
    <li>
      <strong>500 Internal Server Error:</strong> Something is wrong with the website or webpage you’re trying to access, but it’s not your fault.
      It’s like the store closing unexpectedly and you can’t buy anything.
    </li>
    <li>
      <strong>301 Moved Permanently:</strong> The website or webpage you’re trying to access has moved to a new location permanently. It’s like if
      your friend moved to a new house and gave you their new address.
    </li>
    <li>
      <strong>400 Bad Request:</strong> This means that the server was unable to understand the request, either because the request had incomplete url
      or invalid syntax.
    </li>
    <li>
      <strong>403 Forbidden:</strong> You don’t have permission to access the website or webpage you’re trying to access. It’s like if you tried to go
      into a store without permission and the security guard stopped you.
    </li>
    <li>
      <strong>Unknown:</strong> Currently, the status is unknown as we haven&apos;t confirmed the availability of the target URL. Our scanning bot is
      actively running and will provide an update shortly.
    </li>
  </ul>
);

const usageTooltipText = <p>Displays how many times a rule has been triggered the last month.</p>;

export const RulesTableHeaderTargetStatusTooltip: React.FC = () => {
  return <Tooltip>{targetStatusTooltipText}</Tooltip>;
};

export const RulesTableHeaderUsageTooltip: React.FC = () => {
  return <Tooltip>{usageTooltipText}</Tooltip>;
};
