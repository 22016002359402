import { Dispatch } from 'react';
import jwt from 'jsonwebtoken';
import { Action, createDataContext } from './createDataContext';
import { request } from 'gaxios';
import Cookies from 'js-cookie';

export interface State {
  username: string;
  roles: string[];
  token: string;
  isLoggedIn: boolean;
}

const username = (token: string): string => {
  const decoded = jwt.decode(token, { json: true });
  return decoded?.unique_name || decoded?.user;
};
const roles = (userRoles: string): string[] => userRoles?.split(',') || [];

const loginReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        username: username(action.payload.token),
        roles: roles(action.payload.roles),
        token: action.payload.token,
        isLoggedIn: true,
      };
    case 'logout':
      return { username: '', roles: [], token: '', isLoggedIn: false };
    case 'refresh':
      return { ...state, token: action.payload };
    default:
      return state;
  }
};

const login = (dispatch: Dispatch<Action>) => (token: string, roles: string[]) => {
  dispatch({ type: 'login', payload: { token, roles } });
};

const logout = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: 'logout', payload: {} });
};

const isAdmin: (d: Dispatch<Action>, s: State) => () => boolean = (dispatch: Dispatch<Action>, state: State) => () => state.roles.includes('admin');

const refreshToken = (dispatch: Dispatch<Action>, state: State) => async () => {
  const token = state.token;
  const decToken = jwt.decode(token) as any;
  if (decToken && decToken.exp < Date.now() / 1000) {
    try {
      const { data: newToken } = await request<string>({
        url: '/api/auth/refreshtoken',
        headers: { 'content-type': 'text/plain', Authentication: `Bearer ${token}` },
      });
      dispatch({ type: 'refresh', payload: newToken });
      return newToken;
    } catch (e) {
      console.error(`refreshToken: Error: ${e}`);
      dispatch({ type: 'logout', payload: {} });
      throw e;
    }
  }
  return token;
};

let defaultState: State = {
  username: '',
  roles: [],
  token: '',
  isLoggedIn: false,
};

const params = new URLSearchParams(window.location.search);
const token = Cookies.get('azure_token') || params.get('token');
const userRoles = Cookies.get('azure_roles') || '';
if (token) {
  defaultState = {
    username: username(token),
    roles: roles(userRoles),
    token,
    isLoggedIn: true,
  };
}

export const { Provider, Context } = createDataContext<State>(loginReducer, { login, logout, refreshToken, isAdmin }, defaultState);
