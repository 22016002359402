import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Button from '@ingka/button';
import { Tab, TabPanel } from '@ingka/tabs';
import { AnnouncementForm } from 'components/admin/announcement/AnnouncementForm';
import { FilteredPages } from 'components/admin/FilteredPages';
import { Keywords } from 'components/admin/keywords/Keywords';
import { SiteStructureComp } from 'components/admin/siteStructure/SiteStructure';
import { Provider as SiteStructureProvider } from 'hooks/contexts/SiteStructureContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { Context as ErrorMessageContext } from 'hooks/contexts/ErrorMessageContext';
import { UrlSlugSync } from 'components/admin/urlSlugSync/UrlSlugSync';
import Container from 'components/common/Container';
import Tabs from 'components/common/Tabs';
import { PageHeader } from './styles';

export const tabs = [
  {
    route: 'config',
    text: 'Config',
    Component: (): JSX.Element => <Button type={'primary'} text={'Add New Country/Lang'} href={'/metadata/upload'} size={'small'} />,
  },
  {
    route: 'announcements',
    text: 'Announcements',
    Component: (): JSX.Element => <AnnouncementForm />,
  },
  {
    route: 'site-structure',
    text: 'Site Structure',
    Component: (): JSX.Element => (
      <SiteStructureProvider>
        <SiteStructureComp />
      </SiteStructureProvider>
    ),
  },
  {
    route: 'url-slug-sync',
    text: 'Url Slug Sync',
    Component: (): JSX.Element => <UrlSlugSync />,
  },
  {
    route: 'keywords',
    text: 'Keywords',
    Component: (): JSX.Element => <Keywords />,
  },
  {
    route: 'filtered-pages',
    text: 'Filtered Pages',
    Component: (): JSX.Element => <FilteredPages />,
  },
];
export const AdminPage: React.FC = () => {
  const { isAdmin } = useContext(LoginContext);
  const { replace } = useHistory();
  const { tool } = useParams<{ tool: string }>();
  const { goBack } = useHistory();
  const { setErrorMessage } = useContext(ErrorMessageContext);

  useEffect(() => {
    if (!isAdmin()) {
      setErrorMessage({
        message: 'Let’s get you back on track…',
        title: '401 authorisation required',
        action: () => goBack(),
      });
    }
  }, []);

  if (!tabs.map((tab) => tab.route).includes(tool)) {
    return <Redirect to={'/admin/config'} />;
  }
  return (
    <Container>
      <PageHeader>Admin Tools</PageHeader>
      <Tabs
        tabs={tabs.map(({ route, text }) => (
          <Tab key={route} tabPanelId={route} text={text} onClick={() => replace('/admin/' + route)} />
        ))}
        tabPanels={tabs.map(({ route, Component }) => (
          <TabPanel key={route} tabPanelId={route}>
            {tool === route && <Component />}
          </TabPanel>
        ))}
        activeTab={tool}
        defaultActiveTab={tool}
      />
    </Container>
  );
};
