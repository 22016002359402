import { Dispatch } from 'react';
import { Action, createDataContext } from './createDataContext';

interface State {
  isOpen: boolean;
}

const sideNavReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set':
      return { ...state, isOpen: action.payload };
    default:
      return state;
  }
};

const setIsOpen = (dispatch: Dispatch<Action>) => (isOpen: boolean) => {
  dispatch({ type: 'set', payload: isOpen });
};

export const { Provider, Context } = createDataContext<State>(sideNavReducer, { setIsOpen }, { isOpen: true });
