import React, { useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { AvatarImageProps, AvatarSize, AvatarVariant, default as AvatarOriginal } from '@ingka/avatar';
import { Context as LoginContext } from '../../hooks/contexts/LoginContext';

import '@ingka/svg-icon/dist/style.css';
import '@ingka/avatar/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/aspect-ratio-box/dist/style.css';

interface AvatarProps {
  imageProps?: AvatarImageProps;
  size?: AvatarSize;
  variant?: AvatarVariant;
}

const isInParenthesis = /\([^)]*\)/g;

export const Avatar: React.FC<AvatarProps> = ({ size = 'medium', variant = 'secondary', imageProps = undefined }) => {
  const {
    state: { token },
  } = useContext(LoginContext);
  const { name } = jwt_decode<{ name: string }>(token);
  const fullNameRemovedCompanyName = name?.replaceAll(isInParenthesis, '');
  const initialName = fullNameRemovedCompanyName && !imageProps?.src ? formatInitialName(fullNameRemovedCompanyName) : undefined;
  return (
    <AvatarOriginal
      text={initialName}
      screenReaderText={`${initialName ? 'Initials' : 'Image'} of ${name}`}
      imageProps={imageProps}
      size={size}
      variant={variant}
    />
  );
};

const formatInitialName = (name: string) => {
  const nameArray = name.trimEnd().split(' ');
  const firstInitial = nameArray[0][0];
  const lastInitial = nameArray.at(-1)?.[0];
  return firstInitial + lastInitial;
};
