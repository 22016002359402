import React, { useEffect, useState } from 'react';
import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import Toast from '@ingka/toast';
import { mapObjIndexed, pickBy, values } from 'ramda';
import { GET_URL_SLUG_CONFIG } from 'components/admin/queries';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { FlexContainer } from 'components/FlexContainer';
import { getCountryName } from 'opt-util/helpers';
import { UrlSlugSyncInput, useGetUrlSlugConfigQuery, useUpdateUrlSlugConfigMutation } from '__generated__/types';
import * as Styled from 'components/admin/styles';
import { ErrorMessage } from 'components/common/styles';

export const formValuesToConfigInput = (formValue: Record<string, boolean>): UrlSlugSyncInput => {
  const vals = values(
    mapObjIndexed(
      (v, k) => {
        const [country, language] = k.split('/');
        return { country, language, enabled: v };
      },
      pickBy<Record<string, boolean>, Record<string, boolean>>((v, k) => k.includes('/'), formValue),
    ),
  );
  return { config: vals };
};

export const UrlSlugSync = (): JSX.Element => {
  const { loading, data, error } = useGetUrlSlugConfigQuery();
  const [formData, setFormData] = useState<Record<string, boolean>>();

  useEffect(() => {
    const formValues: Record<string, boolean> = {};
    data?.getUrlSlugSyncConfig.forEach((c) => c.configs.forEach((i) => (formValues[`${i.country}/${i.language}`] = i.enabled)));
    setFormData(formValues);
  }, [data]);

  const [message, setMessage] = useState('');
  const [updateConfig] = useUpdateUrlSlugConfigMutation({
    refetchQueries: [{ query: GET_URL_SLUG_CONFIG }],
  });

  const setFieldValue = <K extends keyof Record<string, boolean>>(key: K, value: Record<string, boolean>[K]) =>
    setFormData((prev) => ({ ...prev, [key]: value }));

  const handleSubmit = async (values: Record<string, boolean>) => {
    await updateConfig({ variables: { urlSlugConfig: formValuesToConfigInput(values) } })
      .then(() => {
        setMessage('Saved successfully');
      })
      .catch(() => {
        setMessage('Failed to save');
      })
      .finally(() => {
        setTimeout(() => setMessage(''), 3000);
      });
  };
  if (loading) {
    return (
      <Loading>
        <LoadingBall />
      </Loading>
    );
  }
  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }
  return (
    <>
      {formData &&
        data?.getUrlSlugSyncConfig.map((country) => (
          <div key={country.country}>
            <Toast text={message} isOpen={!!message} />
            <Styled.UrlSlugSyncHeader>{getCountryName(country.country)}</Styled.UrlSlugSyncHeader>
            <FlexContainer direction={'row'}>
              {country.configs.map((c) => {
                const locale = `${c.country}/${c.language}`;
                return c.language === 'en' ? null : (
                  <Styled.UrlSlugCheckboxWrapper key={locale}>
                    <CheckboxInput
                      shouldValidate={false}
                      key={locale}
                      id={locale}
                      label={locale}
                      disabled={c.language === 'en'}
                      onChange={() => setFieldValue(locale, !formData[locale])}
                      checked={formData[locale]}
                    />
                  </Styled.UrlSlugCheckboxWrapper>
                );
              })}
            </FlexContainer>
          </div>
        ))}
      <Button text={'Save'} onClick={async () => formData && (await handleSubmit(formData))} />
    </>
  );
};
