import styled from '@emotion/styled';
import { space100, space125, space25, space50, space75 } from '@ingka/variables';

export const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% auto min-content;
  gap: ${space100};
  align-items: center;
  padding: 0 ${space25} ${space75} 0;
`;

export const FilteringWrapper = styled.div`
  display: flex;
  gap: ${space75};
  align-items: center;
  margin-bottom: ${space125};

  div:last-child {
    margin-left: auto;
    align-items: center;
  }
`;

export const ColumnsAndToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${space50};
  width: fit-content;
  margin-left: auto;
`;
