import React from 'react';
import { useContext, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { TextInputField } from 'components/common/TextInputField';
import { DROPDOWN_QUERY } from 'components/seo/queries';
import { Context as EditorContext, FormKeys } from 'hooks/contexts/EditorContext';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useValidation } from 'hooks/useValidation';
import { GetDropdownOptionsQuery } from '__generated__/types';
import { AutosuggestBox, AutosuggestWrapper } from './styles';

interface CanonicalAutosuggestProps {
  id: FormKeys;
  label: string;
}

export const CanonicalAutosuggest: React.FC<CanonicalAutosuggestProps> = ({ id, label }) => {
  const { state, setField } = useContext(EditorContext);
  const value = (state.formData && state.formData[id]) ?? '';
  const { errorMessage } = useValidation(id, value);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  useOnClickOutside([ref], () => setOpen(false));
  const client = useApolloClient();
  const [data, setData] = useState<GetDropdownOptionsQuery['dropdownOptions']>([]);
  const suggestions = (
    <AutosuggestBox data-testid={'autosuggest-list'}>
      {data.map((d) => (
        <span
          key={d.key}
          onClick={() => {
            setField(id, d.value);
            setOpen(false);
          }}
        >
          {d.text}
        </span>
      ))}
    </AutosuggestBox>
  );

  return (
    <>
      <TextInputField
        id={id}
        shouldValidate
        label={label}
        onChange={(e) => {
          setField(id, e.target.value);
          if (e.target.value.length >= 3) {
            client
              .query<GetDropdownOptionsQuery>({
                query: DROPDOWN_QUERY,
                variables: { text: e.target.value, versionId: state.versionDetails?.versionId },
              })
              .then((d) => {
                setData(d.data.dropdownOptions);
                setOpen(true);
              });
          } else {
            setOpen(false);
          }
        }}
        errorMessage={errorMessage}
        value={value}
      />
      {isOpen && data.length && <AutosuggestWrapper ref={ref}>{suggestions}</AutosuggestWrapper>}
    </>
  );
};
