import React, { BaseSyntheticEvent, FC } from 'react';
import list from '@ingka/ssr-icon/paths/list';
import squareGrid from '@ingka/ssr-icon/paths/square-grid-2x2';
import { CheckboxFields, CheckboxGroup } from 'components/CheckboxGroup';
import { Toggle } from 'components/Toggle';
import * as Styled from './styles';
import { PageWithControlName } from './index';

type ColumnsAndToggleProps = {
  defaultCheckBoxes: CheckboxFields[];
  defaultColumns: CheckboxFields[];
  onColumnsApply: (visibleColumns: string[]) => void;
  pageName?: PageWithControlName;
  activeIndexListOrSquareView?: number;
  onToggleListOrSquareView?: (event: BaseSyntheticEvent, index: number) => void;
};

export const ColumnsAndToggle: FC<ColumnsAndToggleProps> = ({
  onToggleListOrSquareView,
  activeIndexListOrSquareView,
  onColumnsApply,
  defaultCheckBoxes,
  defaultColumns,
  pageName,
}) => {
  const hasToggleView = pageName === 'metadata';
  const hasCheckBoxView = pageName === 'rules' || activeIndexListOrSquareView === 0;
  return (
    <Styled.ColumnsAndToggleWrapper>
      {hasCheckBoxView && (
        <CheckboxGroup onApply={onColumnsApply} defaultCheckboxes={defaultCheckBoxes} hasToggleView={hasToggleView} defaultColumns={defaultColumns} />
      )}
      {hasToggleView && (
        <Toggle
          iconOnly={true}
          buttons={[{ ssrIcon: list }, { ssrIcon: squareGrid }]}
          activeIndex={activeIndexListOrSquareView}
          onClick={onToggleListOrSquareView}
          data-testid={`${pageName}-toggler`}
        />
      )}
    </Styled.ColumnsAndToggleWrapper>
  );
};
