import React, { useContext, useState } from 'react';
import Button from '@ingka/button';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { Context as FeedbackContext } from 'hooks/contexts/FeedbackContext';
import Loading, { LoadingBall } from '@ingka/loading';
import SpeechBubble from '@ingka/ssr-icon/paths/speech-bubble';
import { useSaveSurveyMutation } from '__generated__/types';
import { SurveyModal } from './SurveyModal';
import * as Styled from 'components/seo/styles';
import { SurveyRating } from './SurveyRating';
import { Context as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { FeedbackHeadline } from './FeedbackHeadline';

export const FeedbackSurvey: React.FC = () => {
  const {
    state: { isOpen, feedbackEvent },
    setIsOpen,
  } = useContext(FeedbackContext);
  const [text, setText] = useState('');
  const [rating, setRating] = useState<number | undefined>();
  const [contactMe, setContactMe] = useState<boolean>(false);
  const [saveSurvey, { loading }] = useSaveSurveyMutation();
  const { setStatusMessage } = useContext(StatusMessageContext);

  const resetForm = () => {
    setIsOpen(false);
    setText('');
    setRating(undefined);
    setContactMe(false);
  };

  const submitForm = async () => {
    const res = await saveSurvey({ variables: { survey: { question: 'feedback', answer: text, rating, contactMe, feedbackEvent } } });

    if (!!res.data?.saveSurveyAnswer) {
      setStatusMessage({
        isVisible: true,
        variant: 'positive',
        title: 'Feedback was successfully sent',
        bodyText: 'Thank you for your valuable feedback; we appreciate your input and will use it to improve Optimera.',
        actions: [],
      });
    } else {
      setStatusMessage({
        isVisible: true,
        variant: 'cautionary',
        title: 'Feedback failed to submit',
        bodyText: 'There was an issue submitting your feedback. Please try again in a few minutes.',
        actions: [],
      });
    }

    resetForm();
    setIsOpen(false);
  };

  return (
    <>
      <Styled.FeedbackButtonContainer>
        <Button
          data-testid={'give-feedback-button'}
          type={'primary'}
          expanding
          text={'Give feedback'}
          size={'small'}
          ssrIcon={SpeechBubble}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      </Styled.FeedbackButtonContainer>
      <SurveyModal
        isVisible={isOpen}
        question={'Help us improve'}
        onClickClose={() => setIsOpen(false)}
        buttons={
          <>
            <Button text={'Cancel'} type={'secondary'} size={'small'} onClick={() => resetForm()} />
            <Button
              text="Send feedback"
              type="primary"
              size={'small'}
              disabled={(!text.trim() && !rating) || loading}
              onClick={async () => submitForm()}
            />
          </>
        }
      >
        <FeedbackHeadline />
        {loading && (
          <Loading text={'Please wait...'}>
            <LoadingBall />
          </Loading>
        )}
        {!loading && (
          <>
            <SurveyRating rating={rating} onChange={setRating} />
            <Styled.SurveyTextArea
              label={'Share your feedback (optional)'}
              id="feedback-text-area"
              data-testid="feedback-text-area"
              disabled={loading}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <CheckboxInput
              data-testid={'survey-contact-me'}
              shouldValidate={false}
              id={'surveyContactMe'}
              label={'You may contact me about this feedback'}
              onChange={() => setContactMe(!contactMe)}
              checked={contactMe}
            />
          </>
        )}
      </SurveyModal>
    </>
  );
};
