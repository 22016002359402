import { useContext, useMemo } from 'react';
import { ApolloClient, concat } from '@apollo/client';
import { createAuthMiddleware, cache, httpLink, createLogoutLink } from '../api/graphql';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

export const useApolloClient = () => {
  const { logout, refreshToken } = useContext(LoginContext);

  return useMemo(() => {
    return new ApolloClient({
      link: createLogoutLink(logout).concat(concat(createAuthMiddleware(refreshToken), httpLink)),
      cache: cache,
    });
  }, [logout, refreshToken]);
};
