import React from 'react';
import { createRoot } from 'react-dom/client';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

countries.registerLocale(en);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
// some extra comment
serviceWorker.unregister();
