export const generatePathWithPolicyAndSort = ({
  countryCode,
  policy,
  sortDirection,
  multiplePolicy,
}: {
  policy: string;
  sortDirection: string;
  countryCode: string;
  multiplePolicy?: string[];
}): string => {
  const isMultiplePolicy = multiplePolicy && multiplePolicy.length > 0;
  const policyPath = !isMultiplePolicy
    ? policy + '?'
    : 'multiple?policy=' + multiplePolicy?.map((i, index) => i + (index === 0 && multiplePolicy.length > 1 ? ',' : '')).join('') + '&';
  return `/redirects/${countryCode}/${policyPath}sort=${sortDirection === 'asc' ? 'low' : 'high'}`;
};
