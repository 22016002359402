import React, { useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Button from '@ingka/button';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up-to-line';
import { request } from 'gaxios';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

export const UploadRulesButtons: React.FC = () => {
  const { id, policy } = useParams<{ id: string; policy: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { refreshToken } = useContext(LoginContext);
  const { pathname } = useLocation();
  const [, , curCountry] = pathname.split('/');
  const { setUploadRulesModalValues } = useContext(RulesContext);
  const { setStatusMessage } = useContext(StatusMessageContext);

  const onCancel = async () => {
    const token = await refreshToken();
    try {
      void request({
        method: 'DELETE',
        url: `/api/redirect/rules/${policy}/upload/delete/${id}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      history.push(`/redirects/${curCountry}/${policy}`);
    } catch (error) {}
  };

  const setErrorMessage = () => {
    setIsLoading(false);
    setStatusMessage({
      isVisible: true,
      variant: 'cautionary',
      title: 'Oops!',
      bodyText: 'Something went wrong when publishing the rules! Please try again later.',
      actions: [],
    });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const token = await refreshToken();
    try {
      const response = await request({
        method: 'POST',
        url: `/api/redirect/rules/${policy}/upload/publish/${id}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setIsLoading(false);

        void request({
          method: 'DELETE',
          url: `/api/redirect/rules/${policy}/upload/delete/${id}`,
          headers: { Authorization: `Bearer ${token}` },
        });

        void request({
          method: 'GET',
          url: `/api/redirect/rules/${policy}/start-scanning`,
          params: { filter: JSON.stringify({ field: 'targetStatus', value: null }) },
          headers: { Authorization: `Bearer ${token}` },
        });

        return history.push(`/redirects/${curCountry}/${policy}`, {
          isVisible: true,
          variant: 'positive',
          title: 'Rules uploaded',
          bodyText: 'Publishing the rules finished successfully!',
          actions: [],
        });
      }
      setErrorMessage();
    } catch (error) {
      setErrorMessage();
    }
  };

  return (
    <>
      <Button
        text={'Re-Upload'}
        type={'secondary'}
        size={'small'}
        ssrIcon={arrowUp}
        iconPosition={'trailing'}
        inverseTheme
        onClick={() => setUploadRulesModalValues({ isOpen: true, country: curCountry, policy: policy })}
        disabled={isLoading}
      />
      <Button text={'Discard'} type={'secondary'} size={'small'} inverseTheme onClick={onCancel} disabled={isLoading} />
      <Button
        text={'Publish'}
        type={'primary'}
        inverseTheme
        size={'small'}
        onClick={async () => {
          await onSubmit();
        }}
        loading={isLoading}
        disabled={isLoading}
      />
    </>
  );
};
