import React, { Fragment } from 'react';
import { getCountryName } from 'opt-util/helpers';
import * as Styled from './styles';
import home from '@ingka/ssr-icon/paths/home';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right';
import { NavLink, useLocation } from 'react-router-dom';

type BreadcrumbLink = {
  url: string;
  text: string;
};

type BreadcrumbProps = {
  page: string;
};

const BreadcrumbT: React.FC<BreadcrumbProps> = ({ page }: BreadcrumbProps) => {
  const { pathname } = useLocation();
  const [, , , country, language, type] = pathname?.split('/');
  const breadcrumbTrail: { [key: string]: BreadcrumbLink[] } = {
    defaultUrl: [
      { url: `/redirects/${country}`, text: 'Redirects' },
      { url: '', text: 'Default URL' },
    ],
    upload: [{ url: '/metadata/upload', text: 'Upload' }],
    uploadCountry: [
      {
        url: '/metadata',
        text: `Meta data ${getCountryName(country ?? '')}`,
      },
      { url: `/metadata/upload/${country}/${language}/${type}`, text: `Upload ${type}` },
    ],
  };
  const breadCrumbItems = breadcrumbTrail[page];
  if (!breadCrumbItems) return null;
  return (
    <Styled.StyledContainer>
      <Styled.BreadcrumbContainer>
        <NavLink to={'/dashboard'}>
          <Styled.HomeIcon paths={home} />
        </NavLink>
        <Styled.ArrowIcon paths={chevronRight} />
        {breadCrumbItems.map((loc: BreadcrumbLink, index) => (
          <BreadcrumbItem isLastIndex={index === breadCrumbItems?.length - 1} key={`bc-${index}`} breadcrumbLink={loc} />
        ))}
      </Styled.BreadcrumbContainer>
    </Styled.StyledContainer>
  );
};

const BreadcrumbItem = ({ isLastIndex, breadcrumbLink }: { isLastIndex: boolean; breadcrumbLink: BreadcrumbLink }) => {
  return (
    <>
      {isLastIndex ? (
        <Styled.BreadcrumbLastItem>{breadcrumbLink?.text}</Styled.BreadcrumbLastItem>
      ) : (
        <>
          <Styled.BreadcrumbLink to={breadcrumbLink?.url}>{breadcrumbLink?.text}</Styled.BreadcrumbLink>
          <Styled.ArrowIcon paths={chevronRight} />
        </>
      )}
    </>
  );
};

export default BreadcrumbT;
