import React, { FC, useContext } from 'react';
import { FlexContainer } from 'components/FlexContainer';
import { RichEditor } from 'components/RichEditor';
import { Context as EditorContext } from 'hooks/contexts/EditorContext';
import { FormFields as FormFieldsType } from 'types';
import { EditorContainer } from './styles';

interface FormFieldsProps {
  fields: FormFieldsType[];
}
export const MetaDataCopy: FC<FormFieldsProps> = ({ fields }) => {
  const bodyCopyField = fields.find((field) => field.props.key === 'bodyCopy');
  const h1Field = fields.find((field) => field.props.key === 'h1');
  const introTextField = fields.find((field) => field.props.key === 'introText');

  const {
    state: { formData },
  } = useContext(EditorContext);

  const h1 = formData && formData.h1;
  const bodyCopy = (formData && formData?.bodyCopy) || '';
  const introText = (formData && formData.introText) || '';

  return (
    <FlexContainer direction="column">
      <EditorContainer direction="column">
        <RichEditor id={h1Field?.props.id} namespace="h1-editor" value={`# ${h1}`} hideToolbar editorLabel="H1" outputFormat="raw" compact />
        <RichEditor id={introTextField?.props.id} namespace="intro-text-editor" value={introText} editorLabel="Intro Text" showBlockOptions={false} />
        <RichEditor id={bodyCopyField?.props.id} namespace="body-copy-editor" value={bodyCopy} editorLabel="Body Copy" />
      </EditorContainer>
    </FlexContainer>
  );
};
