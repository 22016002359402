import React from 'react';
import { FlexContainer } from 'components/FlexContainer';
import { FormFields as FormFieldsType } from 'types';
import * as Styled from './styles';

interface FormFieldsProps {
  fields: FormFieldsType[];
}

export const MetaDataForm: React.FC<FormFieldsProps> = ({ fields }) => {
  return (
    <FlexContainer id="meta-data-form" flexWrap="wrap" gap={1}>
      {fields.map((field) => (
        <Styled.FormField id={field.props.key} key={field.props.key} fullWidth={field.props.fullWidth}>
          <field.component {...field.props} />
          {field.props.fieldHelper && field.props.fieldHelper}
        </Styled.FormField>
      ))}
    </FlexContainer>
  );
};
