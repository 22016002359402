import React, { useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalHeader } from '@ingka/modal';
import filtersIcon from '@ingka/ssr-icon/paths/filters';
import { SortInput } from '__generated__/types';
import { ValidityFilter } from './ValidityFilter';
import * as Styled from './styled';
import { FilterContextInputs, FilterContextInputsKey, useFilter } from 'hooks/contexts/FilterContext';
import { PageWithControlName } from '../index';

interface FilterAndSortModalProps {
  type: string;
  defaultFilter: FilterContextInputs;
  defaultSort?: SortInput;
  sort?: SortInput;
  onApply: (filter: FilterContextInputs, sort?: SortInput) => void;
  pageName: PageWithControlName;
}

export type OnFilterChange = (key: FilterContextInputsKey, value: FilterContextInputs[FilterContextInputsKey]) => void;

export const FilterAndSortModal: React.FC<FilterAndSortModalProps> = ({ type, sort, defaultFilter, defaultSort, onApply, pageName }) => {
  const { filter } = useFilter();
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false);
  const [modalFilter, setModalFilter] = useState<FilterContextInputs | undefined>(defaultFilter);
  const [modalSort, setModalSort] = useState<SortInput | undefined>(defaultSort);
  const applyFilters = () => {
    modalFilter && onApply(modalFilter, modalSort);
    setIsFiltersModalOpen(false);
  };
  const onFilterChange: OnFilterChange = (key, value) => {
    setModalFilter((prev) => prev && { ...prev, [key]: value });
  };
  const onSortChange = (sort: SortInput) => setModalSort(sort);

  const onCancel = () => {
    setIsFiltersModalOpen(false);
    setModalFilter(defaultFilter);
  };

  const onClear = () => {
    setModalFilter(defaultFilter);
    defaultSort && setModalSort(defaultSort);
  };
  return (
    <>
      <Styled.ButtonFilter
        data-testid={'filters-btn'}
        text={'Filters'}
        iconPosition={'leading'}
        type={'secondary'}
        ssrIcon={filtersIcon}
        onClick={() => {
          setModalFilter(filter);
          sort && setModalSort(sort);
          setIsFiltersModalOpen(true);
        }}
      />

      <Modal visible={isFiltersModalOpen} handleCloseBtn={onCancel}>
        <Styled.PromptWithWidth
          titleId={''}
          title={''}
          header={<ModalHeader />}
          footer={
            <Styled.ButtonFooter>
              <Button text="Clear all" type="tertiary" onClick={onClear} size="small" />
              <Button text="Cancel" type="secondary" onClick={onCancel} size="small" />
              <Button text="Apply" type="primary" onClick={applyFilters} size="small" />
            </Styled.ButtonFooter>
          }
        >
          <ValidityFilter
            type={type}
            filter={modalFilter}
            pageName={pageName}
            sort={modalSort}
            onFilterChange={onFilterChange}
            onSortChange={onSortChange}
          />
        </Styled.PromptWithWidth>
      </Modal>
    </>
  );
};
