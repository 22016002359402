import styled from '@emotion/styled';
import { FlexContainer } from '../../../components/FlexContainer';
import Text from '@ingka/text';
import { space200, space50, space75 } from '@ingka/variables';

export const CommentControlsContainerDivider = styled.hr`
  margin-top: 0;
`;

export const CommentControlsContainer = styled(FlexContainer)`
  .form-field {
    margin-bottom: 0;
  }
`;

export const CommentText = styled(Text)`
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const NameText = styled(Text)`
  white-space: nowrap;
`;

export const EditSheetCommentSectionContainer = styled(FlexContainer)`
  height: 89vh;
  max-height: 90vh;
  padding-bottom: ${space75};
`;

export const EditSheetCommentListContainer = styled.div`
  overflow-x: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: ${space50} 0 0 0;
  padding-top: ${space75};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const AddCommentContainer = styled(FlexContainer)`
  position: sticky;
  bottom: 0;
`;
