import { getName } from 'i18n-iso-countries';
import { complement, curry, either, find, isEmpty, isNil, map, path, propEq, reduce, split, test, when } from 'ramda';
import { ColumnHeader } from 'features/Rules';
import { Column } from 'types';

export const emptyOrNil = <T>(val: T): boolean => either(isEmpty, isNil)(val);
export const hasValue = complement(emptyOrNil);

export function getPath<T>(dotPath: string, obj: Record<string, unknown>): T | undefined {
  const paths = dotPath.split('.');
  const pathsParsed = map<string, string | number>(when(test(/\d+/), parseInt), paths);
  return path(pathsParsed, obj);
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const propValue = curry((key: string, obj: any) => {
  const pathElem = split('.', key);
  return reduce(
    (acc, curr: string) => {
      if (acc === undefined || acc === null) {
        return undefined;
      }
      if (/^\[.*\]$/.test(curr)) {
        const [propName, propVal] = curr.replace(/\[|\]/g, '').split('=');
        return find(propEq(propName, propVal), acc);
      } else {
        return acc[curr];
      }
    },
    obj,
    pathElem,
  );
});

export const getCountryName = (countryCode: string): string =>
  countryCode.toLowerCase() === 'r1' ? 'Country Zero' : getName(countryCode.toUpperCase(), 'en') || countryCode.toUpperCase();

export const isFutureDate = (dateString: string): boolean => {
  const givenDate: Date = new Date(dateString);
  const currentDate: Date = new Date();
  givenDate.setUTCHours(0, 0, 0, 0);
  currentDate.setUTCHours(0, 0, 0, 0);
  return givenDate > currentDate;
};

export const calculateColumnWidths = (columnHeaders: ColumnHeader[], nullValuePercentage: number): number[] => {
  const numVisibleColumns = columnHeaders.length - 3;
  const nonNullColumnsWidth = 100 - numVisibleColumns * nullValuePercentage;
  const columnWidth = nonNullColumnsWidth / numVisibleColumns;
  const specificColumns = ['Page title', 'Url slug', 'Updated by', 'Updated at'];

  let widths: number[] = columnHeaders.map((header) => {
    if (
      header &&
      (header as Column).label !== undefined &&
      (specificColumns.includes((header as Column).label) ||
        (columnHeaders[columnHeaders.length - 2] &&
          (columnHeaders[columnHeaders.length - 2] as Column).label !== undefined &&
          (header as Column).label === (columnHeaders[columnHeaders.length - 2] as Column).label))
    ) {
      return columnWidth * 2;
    } else if (header) {
      return columnWidth;
    } else {
      return nullValuePercentage;
    }
  });

  const totalWidth = widths.reduce((total, width) => total + width, 0);
  const remainingWidthOrOverflow = 100 - totalWidth;
  const additionalWidthPerColumn = remainingWidthOrOverflow / numVisibleColumns;

  widths = widths.map((width) => {
    if (width !== nullValuePercentage) {
      return width + additionalWidthPerColumn;
    }
    return width;
  });

  return widths;
};
