import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { OptGroup, Option } from '@ingka/select';
import { request } from 'gaxios';
import { TreeMap } from './TreeMap';
import { addPages, sites } from './utils';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { PaddedButton, ResizedInputField, ResizedSelect } from './styles';

const dateRegex = /^((19|20)?[0-9]{2}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01]))$/;

export interface GlobalDataNode {
  _id: string;
  id: string;
  title: string;
  slug: string;
  owner: string;
  type: string;
  startDate: string;
  endDate: string;
  allowLocalSections: boolean;
  allowLocalPages: boolean;
  allowLocalApps: boolean;
  allowPlanners: boolean;
  description: string;
  hierarchy: string[];
  children: GlobalDataNode[];
  _children?: GlobalDataNode[];
  pages: string[];
  sections: string[];
  populated: boolean;
}

export const Global: React.FC = () => {
  const today = new Date().toJSON().slice(0, 10);

  const [renderingDate, setRenderingDate] = useState<string>(today);
  const [site, setSite] = useState<string>('');
  const [rawData, setRawData] = useState<GlobalDataNode>();
  const [data, setData] = useState<GlobalDataNode>();
  const [pages, setPages] = useState<string[]>();

  const { refreshToken } = useContext(LoginContext);

  useEffect(() => {
    if (!dateRegex.test(renderingDate)) {
      return;
    }
    let isMounted = true;
    const updateData = async () => {
      const token = await refreshToken();
      const { data: newData } = await request<GlobalDataNode>({
        url: `/api/catalog-graph/data-gss?date=${renderingDate}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (isMounted) {
        setRawData(newData);
        setData(newData);
      }
    };

    updateData();
    return () => {
      isMounted = false;
    };
  }, [renderingDate, refreshToken]);

  useEffect(() => {
    if (!site) {
      setPages([]);
      return;
    }

    const updatePages = async () => {
      const token = await refreshToken();
      const domain = site === 'r1/en' ? 'country-zero.ikea.com' : 'www.ikea.com';
      const { data: extraPages } = await request<SetStateAction<string[] | undefined>>({
        url: `/api/catalog-graph/editorial?domain=${domain}&siteFolder=${site}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      setPages(extraPages);
    };

    updatePages();
  }, [site, refreshToken]);

  useEffect(() => {
    if (!rawData || !pages) {
      return;
    }
    const newData = addPages(rawData, pages);
    setData(newData);
  }, [pages, rawData]);

  const reset = () => {
    setSite('');
    setPages([]);
  };

  const inputFieldProps = {
    id: 'graph-date-input',
    validation: {
      body: <p>Date in YYYY-MM-DD format.</p>,
    },
    label: 'Date:',
    shouldValidate: true,
    valid: dateRegex.test(renderingDate),
  };

  return (
    <>
      <ResizedInputField type="text" {...inputFieldProps} value={renderingDate} onChange={({ target }) => setRenderingDate(target.value)} />
      <ResizedSelect id="site" label="Editorial Pages from:" value={site} onChange={(e) => setSite(e.target.value)}>
        <Option value={''} disabled hidden />
        <OptGroup groupName="country-zero.ikea.com">
          <Option value="r1/en" name="r1/en" />
        </OptGroup>
        <OptGroup groupName="www.ikea.com">
          {sites.map((c) => (
            <Option value={c} key={c} name={c} />
          ))}
        </OptGroup>
      </ResizedSelect>
      {site && <PaddedButton type="secondary" text="Reset" onClick={reset} />}
      {data && <TreeMap data={data} site={site} />}
    </>
  );
};
