import React, { useContext, useEffect } from 'react';
import { ErrorComp } from '../components/Error';
import { ContainerWrapper } from '../components/common/Container';
import { Context as ErrorContext } from '../hooks/contexts/ErrorMessageContext';

export const NotFoundPage: React.FC = () => {
  const {
    setErrorMessage,
    state: { hasError },
  } = useContext(ErrorContext);
  useEffect(() => {
    setErrorMessage({
      title: '404 Page not found',
      buttonText: 'Go Back to Home',
      message: (
        <span>
          The page you are looking for might have been removed
          <br /> or is temporarily unavailable.
        </span>
      ),
    });
  }, []);
  return <ContainerWrapper>{hasError && <ErrorComp />}</ContainerWrapper>;
};
