import styled from '@emotion/styled';
import {
  colourNeutral2,
  colourNeutral7,
  colourSemanticCaution,
  colourSemanticInformative,
  colourSemanticNegative,
  colourSemanticPositive,
} from '@ingka/variables/colours-css';
import { space200 } from '@ingka/variables';

import { space300, space75, thicknessThin, radiusS, fontSize100, fontSize800, space25, space100 } from '@ingka/variables';

export const DashboardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space300} ${space75} ${space75} ${space75};
  flex-basis: 25%;
  box-sizing: border-box;
  color: ${colourNeutral7};
  text-align: center;
  border-radius: ${radiusS};

  p {
    font-size: ${fontSize100};
  }
`;

export const DashboardTabPanelsMissingDataWrapper = styled.div`
  padding: ${space200};
  background-color: ${colourNeutral2};
`;

export const DashboardBoxHeader = styled.h1<{ type: 'negative' | 'cautious' | 'positive' | 'informative' }>`
  display: flex;
  align-items: center;
  gap: ${space100};
  color: ${({ type }) => {
    return {
      negative: colourSemanticNegative,
      cautious: colourSemanticCaution,
      positive: colourSemanticPositive,
      informative: colourSemanticInformative,
    }[type];
  }};
  height: ${space300};
  font-size: ${fontSize800};
`;
export const DashboardText = styled.p`
  display: flex;
  align-items: center;
  gap: ${space100};
  font-weight: bold;
  margin-top: ${space25};
  margin-bottom: ${space25};
`;
