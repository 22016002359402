import styled from '@emotion/styled';
import { space100, space50 } from '@ingka/variables';
import Pill from '@ingka/pill';

export const Menu = styled.ul`
  width: 200px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: ${space50} ${space100};
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  & > a {
    font-weight: bold;
    text-decoration: none;
  }
`;

export const PillButton = styled(Pill)`
  background-color: transparent;
`;
