import React from 'react';
import * as Styled from './styles';

interface MetaDataModalHeaderProps {
  children: React.ReactNode;
  type: string;
}

export const MetaDataModalHeader: React.FC<MetaDataModalHeaderProps> = ({ children, type }) => {
  return (
    <Styled.ModalHeader>
      <div>
        <Styled.HeaderText tagName="p"> Edit</Styled.HeaderText>
        <Styled.HeaderText tagName="p"> {type.toUpperCase()}</Styled.HeaderText>
      </div>
      {children}
    </Styled.ModalHeader>
  );
};
