import { FilterContextInputsKeyWithoutPage, FilterContextInputsWithoutPage } from '../../../hooks/contexts/FilterContext';
import { getHFBFieldLabel } from './fieldlabels';
import { OnFilterChange } from './FilterAndSortModal';

export type FilterOption = {
  id: string;
  name: string;
  label: string;
  text: string;
  onChange: () => void;
  checked: boolean;
};

type GetFilterOptions = (
  onFilterChange: OnFilterChange,
  {
    filter,
    type,
  }: {
    filter?: FilterContextInputsWithoutPage;
    type?: string;
  },
) => Record<string, FilterOption[]>;

export const getMetadataFilterOptions: GetFilterOptions = (onFilterChange, { filter, type }) => {
  return {
    optimizedArray: [
      {
        id: 'optimized=none',
        name: 'optimized',
        label: 'None',
        text: 'optimized=none',
        onChange: () => onFilterChange('optimized', null),
        checked: filter?.optimized === null,
      },
      {
        id: 'optimized=filter',
        name: 'optimized',
        label: type !== 'filter' ? 'Optimized' : 'Published',
        text: 'optimized=filter',
        onChange: () => onFilterChange('optimized', true),
        checked: filter?.optimized === true,
      },
      {
        id: 'nonOptimized=filter',
        name: 'optimized',
        label: type !== 'filter' ? 'Non-Optimized' : 'Not Published',
        text: 'nonOptimized=filter',
        onChange: () => onFilterChange('optimized', false),
        checked: filter?.optimized === false,
      },
    ],
    validArray: [
      {
        id: 'valid=none',
        name: 'valid',
        label: 'None',
        text: 'valid=none',
        onChange: () => onFilterChange('valid', null),
        checked: filter?.valid === null,
      },
      {
        id: 'valid=filter',
        name: 'valid',
        label: 'Valid Length of Title & Description',
        text: 'valid=filter',
        onChange: () => onFilterChange('valid', true),
        checked: filter?.valid === true,
      },
      {
        id: 'invalid=filter',
        name: 'valid',
        label: 'Invalid Length of Title & Description',
        text: 'invalid=filter',
        onChange: () => onFilterChange('valid', false),
        checked: filter?.valid === false,
      },
    ],
    selfCanonicalArray: [
      {
        id: 'selfCanonical=none',
        name: 'selfCanonical',
        label: 'None',
        text: 'selfCanonical=none',
        onChange: () => onFilterChange('selfCanonicalized', null),
        checked: filter?.selfCanonicalized === null,
      },
      {
        id: 'selfCanonical=filter',
        name: 'selfCanonical',
        label: 'Self Canonicalized',
        text: 'selfCanonical=filter',
        onChange: () => onFilterChange('selfCanonicalized', true),
        checked: filter?.selfCanonicalized === true,
      },
      {
        id: 'nonselfCanonical=filter',
        name: 'selfCanonical',
        label: 'Non-self Canonicalized',
        text: 'nonselfCanonical=filter',
        onChange: () => onFilterChange('selfCanonicalized', false),
        checked: filter?.selfCanonicalized === false,
      },
    ],
    hasProductsArray: [
      {
        id: 'hasProducts=none',
        name: 'hasProducts',
        label: 'None',
        text: 'hasProducts=none',
        onChange: () => onFilterChange('hasProducts', null),
        checked: filter?.hasProducts === null,
      },
      {
        id: 'hasProducts=filter',
        name: 'hasProducts',
        label: 'Has Products',
        text: 'hasProducts=filter',
        onChange: () => onFilterChange('hasProducts', true),
        checked: filter?.hasProducts === true,
      },
      {
        id: 'hasNoProducts=filter',
        name: 'hasProducts',
        label: 'Has No Products',
        text: 'hasNoProducts=filter',
        onChange: () => onFilterChange('hasProducts', false),
        checked: filter?.hasProducts === false,
      },
    ],
  };
};

export const getRedirectFilterOptions: GetFilterOptions = (onFilterChange, { filter }) => {
  return {
    targetStatusArray: [
      {
        id: 'targetStatus=none',
        name: 'targetStatus',
        label: 'None',
        text: 'targetStatus=none',
        onChange: () => onFilterChange('targetStatus', null),
        checked: filter?.targetStatus === null,
      },
      {
        id: 'targetStatus=200',
        name: 'targetStatus',
        label: '200',
        text: 'targetStatus=200',
        onChange: () => onFilterChange('targetStatus', 200),
        checked: filter?.targetStatus === 200,
      },
      {
        id: 'targetStatus=301',
        name: 'targetStatus',
        label: '301',
        text: 'targetStatus=301',
        onChange: () => onFilterChange('targetStatus', 301),
        checked: filter?.targetStatus === 301,
      },
      {
        id: 'targetStatus=404',
        name: 'targetStatus',
        label: '404',
        text: 'targetStatus=404',
        onChange: () => onFilterChange('targetStatus', 404),
        checked: filter?.targetStatus === 404,
      },
    ],
    usageArray: [
      {
        id: 'usage=none',
        name: 'usage',
        label: 'None',
        text: 'usage=none',
        onChange: () => onFilterChange('invocations', null),
        checked: filter?.invocations === null,
      },
      {
        id: 'usage=high',
        name: 'usage',
        label: 'High ( > 100 )',
        text: 'usage=high',
        onChange: () => onFilterChange('invocations', 'high'),
        checked: filter?.invocations === 'high',
      },
      {
        id: 'usage=low',
        name: 'usage',
        label: 'Low ( 10 < )',
        text: 'usage=low',
        onChange: () => onFilterChange('invocations', 'low'),
        checked: filter?.invocations === 'low',
      },
    ],
  };
};

export const filterPillOptions: Record<
  string,
  {
    field: FilterContextInputsKeyWithoutPage;
    label: (filter: FilterContextInputsWithoutPage) => string | null | undefined;
  }
> = {
  optimized: {
    field: 'optimized',
    label: (filter: FilterContextInputsWithoutPage) => (filter?.optimized === true ? 'Optimized' : 'Non-Optimized'),
  },
  selfCanonicalized: {
    field: 'selfCanonicalized',
    label: (filter: FilterContextInputsWithoutPage) => (filter.selfCanonicalized === true ? 'Self Canonicalized' : 'Non-self Canonicalized'),
  },
  valid: {
    field: 'valid',
    label: (filter: FilterContextInputsWithoutPage) =>
      filter.valid === true ? 'Valid Length of Title & Description' : 'Invalid Length of Title & Description',
  },
  hasProducts: {
    field: 'hasProducts',
    label: (filter: FilterContextInputsWithoutPage) => (filter.hasProducts === true ? 'Has Products' : 'Has No Products'),
  },
  needsReview: {
    field: 'needsReview',
    label: (filter: FilterContextInputsWithoutPage) => 'Needs Review',
  },
  homeFurnishingBusinessId: {
    field: 'homeFurnishingBusinessId',
    label: (filter: FilterContextInputsWithoutPage) => filter.homeFurnishingBusinessId && getHFBFieldLabel(filter.homeFurnishingBusinessId),
  },
  targetStatus: {
    field: 'targetStatus',
    label: (filter: FilterContextInputsWithoutPage) => filter.targetStatus?.toString(),
  },
  invocations: {
    field: 'invocations',
    label: (filter: FilterContextInputsWithoutPage) => (filter.invocations === 'high' ? 'High Usage' : 'Low Usage'),
  },
};
