import React, { useContext, useEffect, useState } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import { Option } from '@ingka/select';
import { request } from 'gaxios';
import { Sugiyama } from './Sugiyama';
import { sites } from './utils';
import { ResizedSelect } from './styles';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

export interface Struct {
  id: string;
  parentIds: string[];
}

interface Resource {
  id: string;
  name: string;
  subCategories: string[];
  url: string;
  deepId?: string;
  searchConsoleData: {
    search_impressions: number;
    search_clicks: number;
    search_ctr: string;
    avg_position: number;
  };
}

export interface CatalogReply {
  structure: Struct[];
  resources: Resource[];
}

export const Catalog: React.FC = () => {
  const [country, setCountry] = useState('');
  const [node, setNode] = useState('products');
  const [categories, setCategories] = useState<(Resource | undefined)[]>([]);
  const [data, setData] = useState<CatalogReply>();
  const [isLoading, setIsLoading] = useState(false);
  const { refreshToken } = useContext(LoginContext);

  useEffect(() => {
    let isMounted = true;
    const updateData = async () => {
      if (country === '') {
        return;
      }
      setIsLoading(true);
      const token = await refreshToken();
      const { data: newData } = await request<CatalogReply>({
        url: `/api/catalog-graph/data?domain=www.ikea.com&siteFolder=${country}&parentNodeId=${node}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (isMounted) {
        setData(newData);
        const products = newData.resources.find((r) => r.id === 'products');
        if (products) {
          const subCategories = products.subCategories.map((sub) => newData.resources.find((r) => r.id === sub));
          setCategories(subCategories);
        }
        setIsLoading(false);
      }
    };

    updateData();
    return () => {
      isMounted = false;
    };
  }, [country, refreshToken, node]);

  const updateCountry = (newCountry: string) => {
    setNode('products');
    setCountry(newCountry);
  };

  return (
    <>
      <ResizedSelect id="website" label="Select website" value={country} onChange={(e) => updateCountry(e.target.value)}>
        <Option value={''} disabled hidden />
        {sites.map((site) => (
          <Option value={site} key={site} name={site} />
        ))}
      </ResizedSelect>

      {country && (
        <ResizedSelect id="category" label="Select category" value={node} onChange={(e) => setNode(e.target.value)}>
          <Option value={'products'} name="" />
          {categories.map((cat) => (
            <Option value={cat?.id} key={cat?.id} name={cat?.name} />
          ))}
        </ResizedSelect>
      )}

      {data && !isLoading && <Sugiyama data={data} />}
      {isLoading && (
        <Loading text={'Please wait...'}>
          <LoadingBall />
        </Loading>
      )}
    </>
  );
};
