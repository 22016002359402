import styled from '@emotion/styled';
import IngkaTable from '@ingka/table';
import Text from '@ingka/text';
import { space50 } from '@ingka/variables';
import { colourNeutral2, colourTextAndIcon1 } from '@ingka/variables/colours-css';

const policyListWidths = [20, 35, 25, 15, 5];

export const Table = styled(IngkaTable)`
  border: none;
  overflow: inherit !important;

  th {
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: none !important;

    ${policyListWidths.map(
      (width, index) => `
      &:nth-of-type(${index + 1}) {
        width: ${width}%;
      }
    `,
    )}
    &:nth-of-type(4) {
      text-align: end;
    }
  }

  .text {
    color: ${colourTextAndIcon1} !important;
  }

  td {
    height: 47px;
    padding: ${space50} !important;
    vertical-align: middle;
    word-break: break-word;
    border-bottom: none !important;

    ${policyListWidths.map(
      (width, index) => `
      &:nth-of-type(${index + 1}) {
        width: ${width}%;
      }
    `,
    )}
    &:nth-of-type(4) {
      text-align: end;
    }

    .btn__inner {
      height: 32px;
      max-height: 32px;
      min-height: 32px;
    }
  }

  tr {
    :nth-of-type(odd) {
      background-color: ${colourNeutral2};
    }
  }
`;

export const CountryName = styled(Text)`
  text-transform: capitalize;
`;

export const ShortUrlHeader = styled(Text)`
  text-transform: capitalize;
`;
