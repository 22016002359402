import React, { useContext, useEffect, useState } from 'react';
import Button from '@ingka/button';
import { SiteStructureForm } from './Edit';
import { SiteStructureItem } from './Item';
import { SiteStructure, Context as SiteStructureContext } from 'hooks/contexts/SiteStructureContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

export interface SiteStructureItemProps {
  data: SiteStructure;
}

const AddNew: React.FC = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { addNew } = useContext(SiteStructureContext);
  return showAddForm ? (
    <SiteStructureForm
      data={{
        slug: '',
        allowLocalApps: false,
        allowLocalPages: false,
        allowLocalSections: false,
        allowPlanners: false,
        owner: '',
        type: '',
        _id: '',
        description: '',
      }}
      showId={false}
      submitAction={addNew}
      setEdit={setShowAddForm}
    />
  ) : (
    <Button text={'Add new'} type="primary" onClick={() => setShowAddForm(true)} size={'small'} />
  );
};

export const SiteStructureComp: React.FC = () => {
  const {
    state: { data },
    fetchAll,
  } = useContext(SiteStructureContext);
  const { refreshToken } = useContext(LoginContext);

  useEffect(() => {
    const fetch = async () => {
      const token = await refreshToken();
      fetchAll(token);
    };

    fetch();
  }, []);

  return (
    <>
      <AddNew />
      {data.map((i) => (
        <SiteStructureItem key={i._id} data={i} />
      ))}
      <AddNew />
    </>
  );
};
