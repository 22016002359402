import styled from '@emotion/styled';
import { Sheets } from '@ingka/modal';
import { Tab, TabPanel } from '@ingka/tabs';
import { fontSize50, fontSize500, fontSize75, space100 } from '@ingka/variables';
import { colourNeutral2 } from '@ingka/variables/colours-css';
import { space125 } from '@ingka/variables';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const FullScreenSheet = styled(Sheets)`
  width: 100%;
  max-width: 100vw;
  .sheet__content {
    display: flex;
    min-height: 100%;
  }
`;

export const MessageContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const Diff = styled.div`
  margin-top: ${space125};
  padding: ${space125};
  background: ${colourNeutral2};
  width: 100%;
  display: flex;
  font-size: ${fontSize50};
  justify-content: space-between;
`;

export const EditorConfirmTitle = styled.h2`
  font-size: ${fontSize500};
  margin-bottom: ${space100};
`;

export const EditorConfirmText = styled.p`
  font-size: ${fontSize75};
`;

export const ModalSideBar = styled.section`
  display: flex;
  width: 15rem;
  min-width: 15rem;
  max-width: 15rem;
  padding: 12px;
  flex-direction: column;
  gap: 12px;
  height: 100%;
`;

export const ModalSideBarTab = styled(Tab)`
  font-size: ${fontSize50};
  font-weight: normal;
`;

export const ModalSideBarTabPanel = styled(TabPanel)`
  padding: 0;
`;
