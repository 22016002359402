import { sendEvent, init, EpisodEvent } from '@ingka-group-digital/episod-client-js';
import { envIsLocalOrTest, episodConfig } from 'environment';

export let initialized: boolean = false;
export const initializeEpisod = () => {
  init(episodConfig);
  initialized = true;
};

initializeEpisod();

export const sendEpisodEvent = async (name: string, eventDetails?: object) => {
  if (envIsLocalOrTest()) return;
  if (!initialized) initializeEpisod();

  const event: EpisodEvent = {
    event_origin: 'optimera',
    event_name: name,
    event_details: eventDetails,
  };

  sendEvent(event);
};

const EventNames = {
  PAGE_LOAD: 'page_load',
};

export const sendPageLoadEvent = async (page: string) => {
  const eventDetails = {
    category: EventNames.PAGE_LOAD,
    label: page,
  };

  await sendEpisodEvent(EventNames.PAGE_LOAD, eventDetails);
};
