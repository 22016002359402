import { InitConfig } from '@ingka-group-digital/episod-client-js';
import { MetaDataCompleteFragment } from './__generated__/types';

export interface ValidationOptions {
  min?: number;
  max?: number;
}

type ValidationRules = {
  [P in keyof Omit<MetaDataCompleteFragment, '__typename'>]?: ValidationOptions;
};
const envValidation = JSON.parse(process.env.REACT_APP_VALIDATION_OPTIONS || '{}');
const validation: ValidationRules = {
  pageTitle: { min: 1, max: 60 },
  description: { min: 1 },
  ...envValidation,
};
export const environment = {
  serviceUrl: process.env.REACT_APP_OPTIMERA_SERVICE_URL || '/api',
  backendLoginPath: '/auth/login',
  validation: validation,
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
};

export const envIsProduction = () => environment.environment === 'prod';
export const envIsLocalOrTest = () => environment.environment === 'local' || environment.environment === 'test';

const cookieDomain = 'optimera.ingka.com';
const url = envIsProduction() ? `https://web-api.ikea.com/episod/collect` : 'https://web-api.cte.ikeadt.com/episod/collect';

const xClientId = envIsProduction() ? '1ea83eeb-f16f-43d0-a11d-2487f62fb456' : 'a0bb26de-64e2-4000-8b31-076cdb84c29d';

export const episodConfig: InitConfig = {
  cookieDomain,
  url,
  xClientId,
};
