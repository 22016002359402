import styled from '@emotion/styled';
import SSRIcon from '@ingka/ssr-icon';
import { NavLink } from 'react-router-dom';
import { fontSize50, space100, space125, space25 } from '@ingka/variables';

export const StyledContainer = styled.div`
  margin-bottom: ${space125};
`;

export const HomeIcon = styled(SSRIcon)`
  width: ${space125};
`;
export const ArrowIcon = styled(SSRIcon)`
  width: ${space100};
  color: #929292;
`;

export const BreadcrumbLink = styled(NavLink)`
  text-decoration: none;
`;

export const BreadcrumbLastItem = styled.p`
  color: #003e72;
`;

export const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${space25};
  font-size: ${fontSize50};
`;
