import { FC, useState } from 'react';
import { DropdownInput, DropdownOption } from 'components/common/DropdownInput';
import * as Styled from './styled';

export const monthOptions: DropdownOption[] = [
  { text: 'January', key: 'jan', value: 'Jan' },
  { text: 'February', key: 'feb', value: 'Feb' },
  { text: 'March', key: 'mar', value: 'Mar' },
  { text: 'April', key: 'apr', value: 'Apr' },
  { text: 'May', key: 'may', value: 'May' },
  { text: 'June', key: 'jun', value: 'Jun' },
  { text: 'July', key: 'jul', value: 'Jul' },
  { text: 'August', key: 'aug', value: 'Aug' },
  { text: 'September', key: 'sep', value: 'Sep' },
  { text: 'October', key: 'oct', value: 'Oct' },
  { text: 'November', key: 'nov', value: 'Nov' },
  { text: 'December', key: 'dec', value: 'Dec' },
];

const getCurrentYear = (): number => new Date().getFullYear();

const getYearOptions = (): DropdownOption[] => {
  const currentYear = getCurrentYear();
  const past50Years = Array.from({ length: 51 }, (_, i) => currentYear + i * -1);

  return past50Years.map((year: number) => ({
    text: year.toString(),
    key: year.toString(),
    value: year.toString(),
  }));
};

const getYear = (year?: number): DropdownOption => {
  const currentYear = getYearOptions()[0];
  if (!year) return currentYear;

  return getYearOptions().find((y) => y.text === year?.toString()) || currentYear;
};

const getMonth = (month?: string): DropdownOption => {
  const currentMonth = monthOptions[new Date().getMonth()];
  if (!month) return monthOptions[new Date().getMonth()];

  return monthOptions.find((m) => m.value === month) || currentMonth;
};

interface MonthCalendarProps {
  label: string;
  value?: { year?: number; month?: string };
  disabled?: boolean;
  tooltip?: string;
  defaultDate?: { year: number; month: string };
  onUpdate: ({ year, month }: { year: number; month: string }) => void;
}

const MonthCalendar: FC<MonthCalendarProps> = ({ label, value, defaultDate, onUpdate, disabled, tooltip }) => {
  const [selectedYear, setSelectedYear] = useState<DropdownOption>(getYear(value?.year || defaultDate?.year));
  const [selectedMonth, setSelectedMonth] = useState<DropdownOption>(getMonth(value?.month || defaultDate?.month));

  const handleOnUpdateYear = (year: string) => {
    const newYear = getYear(Number(year));
    if (newYear) {
      setSelectedYear(newYear);
      onUpdate({ year: Number(newYear.text), month: selectedMonth.value });
    }
  };

  const handleOnUpdateMonth = (month: string) => {
    const newMonth = getMonth(month);
    if (newMonth) {
      setSelectedMonth(newMonth);
      onUpdate({ year: Number(selectedYear.value), month: newMonth.value });
    }
  };

  return (
    <fieldset>
      <legend>{label}</legend>
      <DropdownInput
        isReadOnly={disabled}
        id={'year'}
        label={'Year'}
        options={getYearOptions()}
        onChange={(e) => handleOnUpdateYear(e.target.value)}
        value={selectedYear.value}
      />
      <DropdownInput
        isReadOnly={disabled}
        id={'month'}
        label={'Month'}
        options={monthOptions}
        onChange={(e) => handleOnUpdateMonth(e.target.value)}
        value={selectedMonth.value}
      />
      <Styled.Tooltip>{tooltip}</Styled.Tooltip>
    </fieldset>
  );
};

export default MonthCalendar;
