/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import List from '@ingka/list';
import SSRIcon from '@ingka/ssr-icon';
import globe from '@ingka/ssr-icon/paths/globe';
import Text from '@ingka/text';
import Container from 'components/common/Container';
import { goToOptimeraPageScript } from 'opt-util/goToOptimeraPageScript';
import * as Styled from './styles';

export const BookmarkPage: React.FC = () => {
  const listItems = ['Browse IKEA site.', 'Press the button in the Bookmarks bar.', 'The PLP opens in OPTIMERA'];

  const injectJsonDataToTemplate = (tokens: Record<string, any>, template: string): string =>
    Object.entries(tokens).reduce((previous, [token, data]) => previous.replaceAll(token, JSON.stringify(data)), template);

  const createBookmarklet = (content: string): string => `javascript:(${content})()`;

  const createGoToOptimeraPageBookmarklet = (type: 'production' | 'development'): string => {
    const tokens = {
      __OPTIMERA_REDIRECT_PATTERNS__:
        type === 'development'
          ? {
              '.*': window.location.origin,
            }
          : {
              '(www\\.)?cte\\.ikeadt\\.com': 'https://optimera-cte.ingka.com',
              '(www\\.)?ikea\\.com': 'https://optimera.ingka.com',
              '(www\\.)?ppe\\.ikeadt\\.com': 'https://optimera.ingka.com',
              'country-zero\\.ikea\\.com': 'https://optimera.ingka.com',
              'optimera\\.ingka\\.com': 'https://optimera.ingka.com',
              'optimera\\-cte\\.ingka\\.com': 'https://optimera-cte.ingka.com',
            },
    };

    return createBookmarklet(injectJsonDataToTemplate(tokens, goToOptimeraPageScript.toString()));
  };

  const isLocalhost = window.location.hostname === 'localhost';

  return (
    <Container>
      <Styled.BookmarkletWrapper direction="column">
        <Text tagName="p">
          Here you can activate the feature <strong>Open page in Optimera</strong>
        </Text>
        <Text tagName="p">This feature is very helpful when you’re browsing the IKEA site and you want edit or investigate a PLP in OPTIMERA.</Text>

        <Text tagName="h2" bodySize="l">
          <strong>How to install</strong>
        </Text>
        <Text tagName="p">Drag this button to your Bookmarks bar.</Text>

        <Styled.BookmarkletButtonWrapper justify="center">
          <Styled.BookmarkletButton
            role="button"
            className="btn leading-icon btn--secondary"
            ref={(node) => node && node.setAttribute('href', createGoToOptimeraPageBookmarklet(isLocalhost ? 'development' : 'production'))}
          >
            <span className="btn__inner">
              <SSRIcon paths={globe} height={'100'} viewBox={'0 0 24 24'} />
              <span className="btn__label">Open in Optimera</span>
            </span>
          </Styled.BookmarkletButton>
          <Styled.BookmarkletArrow
            width="262"
            height="237"
            viewBox="0 0 262 237"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="Bookmarklet_arrow__Yn2SN"
          >
            <path d="M81.8119 66.4913L49.0549 83.2947L36.1304 57.8036L112.42 18.6695L151.193 95.1426L125.763 108.187L108.992 75.1089L97.528 110.436C87.7608 140.534 104.127 172.814 134.084 182.535C164.04 192.256 223.363 184.538 233.131 154.44C218.48 199.587 170.176 224.366 125.241 209.784C80.3066 195.202 55.7567 146.782 70.4075 101.635L81.8119 66.4913Z"></path>
          </Styled.BookmarkletArrow>
        </Styled.BookmarkletButtonWrapper>

        <Text tagName="h2" bodySize="l">
          <strong>How to use it</strong>
        </Text>
        <List ordered variation={'medium'}>
          {listItems.map((listItem, index) => {
            return <li key={index}>{listItem}</li>;
          })}
        </List>
      </Styled.BookmarkletWrapper>
    </Container>
  );
};
