import React, { FC, Fragment, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Checkbox from '@ingka/checkbox';
import handleBar from '@ingka/ssr-icon/paths/handle';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import Text from '@ingka/text';
import { Column as RuleTableColumn } from 'types';
import { DeleteRuleModal } from './DeleteRuleModal';
import { RuleType } from './index';
import { StatusIcon } from './statusIcon';
import * as StyledLocal from './styled';
import { FloatingMenuWithPillButton, FloatingMenuWithPillMenuItem } from '../../components/FloatingMenuWithPillButton';

const Styled = { ...StyledLocal };

type RuleProps = {
  rule: RuleType;
  onCheckboxClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
  isEditingAllowed: boolean;
  isSelected: boolean;
  columns: RuleTableColumn[];
  isGlobal: boolean;
  id: string;
  hideActions: boolean;
  hideActionsIcon: boolean;
  policy: string;
};
const shortenNumber = (number: number): string => {
  const suffixes = ['', 'k', 'M', 'B', 'T'];
  const suffixIndex = Math.floor(Math.log10(number) / 3);
  const shortNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
  return shortNumber + suffixes[suffixIndex];
};
const Rule: FC<RuleProps> = ({
  rule: { docRefId, name, invocations, matchURL, redirectType, targetURL, targetStatus, dateEnd, dateStart, userModifiedBy, usage },
  onCheckboxClick,
  onEditClick,
  onDeleteClick,
  isEditingAllowed,
  isSelected,
  columns,
  isGlobal,
  id,
  policy,
  hideActions,
  hideActionsIcon,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const invocationsShortend = invocations >= 1000 ? shortenNumber(invocations) : invocations;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: docRefId });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const floatingMenuItems: FloatingMenuWithPillMenuItem[] = [
    {
      onClick: onEditClick,
      text: 'Edit',
      icon: pencil,
      dataTestId: 'edit-rule-modal-button',
    },
    {
      onClick: () => setIsDeleteModalOpen(true),
      text: 'Delete',
      icon: trashCan,
      dataTestId: 'delete-rule-modal-button',
    },
  ];
  const cells = columns.map((column: RuleTableColumn, index) => {
    return (
      <Fragment key={index}>
        {column?.field === 'matchURL' && (
          <td>
            <Text tagName="span" bodySize="s">
              {matchURL}
            </Text>
          </td>
        )}

        {column?.field === 'targetURL' && (
          <td>
            <Text tagName="span" bodySize="s">
              <a href={targetURL} target="_blank" rel="noreferrer">
                {targetURL}
              </a>
            </Text>
          </td>
        )}
        {column?.field === 'name' && (
          <td>
            <Text tagName="span" bodySize="s">
              {name}
            </Text>
          </td>
        )}
        {column?.field === 'invocations' && (
          <Styled.InvocationColumn style={{ textAlign: 'right' }}>
            <Text tagName="span" bodySize="s">
              {invocationsShortend}
            </Text>
          </Styled.InvocationColumn>
        )}
        {column?.field === 'dateStart' && (
          <td>
            <Text tagName="span" bodySize="s">
              {dateStart && new Date(dateStart).toLocaleDateString()}
            </Text>
          </td>
        )}
        {column?.field === 'dateEnd' && (
          <td>
            <Text tagName="span" bodySize="s">
              {dateEnd ? new Date(dateEnd).toLocaleDateString() : 'None'}
            </Text>
          </td>
        )}
        {column?.field === 'redirectType' && <td>{redirectType}</td>}
        {column?.field === 'targetStatus' && !isGlobal && (
          <td>
            <StatusIcon status={targetStatus} startDate={dateStart} />
          </td>
        )}
        {column?.field === 'userModifiedBy' && (
          <td>
            <Text tagName="span" bodySize="s">
              {userModifiedBy?.email}
            </Text>
          </td>
        )}
      </Fragment>
    );
  });
  return (
    <tr id={id} ref={setNodeRef} style={style} data-testid={`rule-row`}>
      {isGlobal && (
        <td {...attributes} {...listeners} data-testid={`drag-rule-button-${id}`}>
          <Styled.PillButton iconOnly size={'small'} ssrIcon={handleBar} />
        </td>
      )}
      {!hideActions && (
        <td>
          <Checkbox
            id={docRefId}
            name="selectRowCheckbox"
            data-testid={'rule-checkbox'}
            checked={isSelected}
            onChange={onCheckboxClick}
            value={docRefId}
          />
        </td>
      )}
      {cells}
      {(!hideActionsIcon || !hideActions) && (
        <td>
          {!hideActions && (
            <Styled.EditDeleteButtonContainer>
              <Styled.EditDeleteButton
                type={'tertiary'}
                text={'Delete'}
                size={'small'}
                ssrIcon={trashCan}
                onClick={() => setIsDeleteModalOpen(true)}
                disabled={!isEditingAllowed}
                data-testid="delete-rule-modal-button"
              />
              <Styled.EditDeleteButton
                type={'secondary'}
                text={'Edit'}
                size={'small'}
                ssrIcon={pencil}
                data-testid="edit-rule-modal-button"
                onClick={onEditClick}
                disabled={!isEditingAllowed}
              />
            </Styled.EditDeleteButtonContainer>
          )}
          {!hideActionsIcon && <FloatingMenuWithPillButton menuItems={floatingMenuItems} disabled={!isEditingAllowed} />}
          {isDeleteModalOpen && (
            <DeleteRuleModal
              isVisible={isDeleteModalOpen}
              onSubmit={() => {
                onDeleteClick();
                setIsDeleteModalOpen(false);
              }}
              onCancel={() => setIsDeleteModalOpen(false)}
              selectedRules={[docRefId]}
              ruleName={name}
              ruleTargetURL={targetURL}
              policy={policy}
            />
          )}
        </td>
      )}
    </tr>
  );
};

export { Rule };
