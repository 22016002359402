import React, { Fragment, PropsWithChildren, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { GlobalDataNode } from './Global';
import { collapse, height, rectSpacing, rectW, updateTreeMap } from './utils';
import { InfoBox, InfoContainer, TreeMapSVG } from './styles';

interface TreeMapProps {
  data: GlobalDataNode;
  site: string;
}

export interface Info {
  title: string;
  id: string;
  description: string;
  owner: string;
  slug: string;
  startDate: string;
  endDate: string;
  allowLocalSections: boolean;
  allowLocalPages: boolean;
  allowLocalApps: boolean;
  allowPlanners: boolean;
  sections: string[];
  pages: string[];
}

export const TreeMap: React.FC<TreeMapProps> = ({ data, site }) => {
  const domain = site === 'r1/en' ? 'country-zero.ikea.com' : 'www.ikea.com';

  const ref = useRef<SVGSVGElement>(null);
  const [info, setInfo] = useState<Info | null>();

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = '';
    }

    const svg = d3.select(ref.current);

    // Assigns the data to a hierarchy using parent-child relationships
    const root = d3.hierarchy(data);

    // Make sure we have at least one child
    if (!root.children) {
      return;
    }

    const transform = d3.zoomIdentity.translate((root.children.length * (rectW + rectSpacing)) / 2 - rectW / 2, 0);
    const zoom = d3.zoom();
    const g = svg
      .attr('viewBox', `-50, -200, ${root.children.length * (rectW + rectSpacing) + 200}, ${height}`)
      .call(
        zoom.on('zoom', function (this, event) {
          g.attr('transform', event.transform);
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        }) as any,
      )
      .append('g');
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    svg.call(zoom.transform as any, transform);
    root.children.forEach(collapse);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    updateTreeMap(root, g as any, setInfo);
  }, [data]);

  return (
    <>
      <TreeMapSVG ref={ref} site={site} />
      <InfoContainer>
        {!info && (
          <InfoBox>
            <b>Help</b>
            <br />
            <br />
            Hover over nodes to show more information. Expand nodes by clicking on them. Click and drag the background to move the viewport. Zoom in
            and out with the scroll wheel/touchpad. Enter a date in the future or past Select a site to load editorial pages from
          </InfoBox>
        )}

        {info && (
          <InfoBox>
            <b>{info.title}</b>
            <br />
            {info.description || '-'}
            <br />
            <br />
            <b>Owner:</b> {info.owner}
            <br />
            <b>ID:</b> {info.id || '-'}
            <br />
            <b>Slug:</b> {info.slug}
            <br />
            <b>Start date:</b> {info.startDate || '-'}
            <br />
            <b>End date:</b> {info.endDate || '-'}
            <br />
            <br />
            <b>Allow local sections:</b> {info.allowLocalSections.toString()}
            <br />
            <b>Allow local pages:</b> {info.allowLocalPages.toString()}
            <br />
            <b>Allow local apps:</b> {info.allowLocalApps.toString()}
            <br />
            <b>Allow planners:</b> {info.allowPlanners.toString()}
          </InfoBox>
        )}
        {info?.sections && info.sections.length > 0 && (
          <InfoLinks links={info.sections} site={site} domain={domain}>
            <b>Local Sections</b>
            <br />
          </InfoLinks>
        )}
        {info?.pages && info.pages.length > 0 && (
          <InfoLinks links={info.pages} site={site} domain={domain}>
            <b>Local Pages</b>
            <br />
          </InfoLinks>
        )}
      </InfoContainer>
    </>
  );
};

interface InfoLinksProps {
  links: string[];
  domain: string;
  site: string;
}

const InfoLinks: React.FC<PropsWithChildren<InfoLinksProps>> = ({ links, domain, site, children }) => (
  <InfoBox>
    {children}
    {links.map((link, index) => (
      <Fragment key={index}>
        <br />
        <a href={`https://${domain}/${site}/${link}`}>
          /{site}/{link}
        </a>
      </Fragment>
    ))}
  </InfoBox>
);
