import { useContext } from 'react';
import { request } from 'gaxios';
import FileSaver from 'file-saver';
import { FilterInput } from '../__generated__/types';
import { Context as LoginContext } from './contexts/LoginContext';

type VersionType = { country: string; language: string; type: string };
export type FileType = 'csv' | 'xlsx' | 'json';

export const formatContentTypes = {
  csv: 'text/csv;charset=utf-8',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  json: 'application/json;charset=utf-8',
};

export const useMetaDataExport = () => {
  const { refreshToken } = useContext(LoginContext);

  const exportMetaData = async (versionId: string, filterState: FilterInput, { country, language, type }: VersionType, format: FileType = 'xlsx') => {
    const filter = {
      textFilter: filterState.textFilter,
      valid: filterState.valid,
      optimized: filterState.optimized,
      selfCanonicalized: filterState.selfCanonicalized,
      hasProducts: filterState.hasProducts,
      needsReview: filterState.needsReview,
      page: 0,
      pageSize: 10,
      homeFurnishingBusinessId: filterState.homeFurnishingBusinessId,
    };

    const token = await refreshToken();

    const res = await request<string>({
      method: 'POST',
      url: `/api/optimera/metadata/export/${format}`,
      data: { versionId, filter, country, language, type },
      responseType: 'blob',
      headers: { authorization: `Bearer ${token}` },
    });
    const blob = new Blob([res.data], {
      type: formatContentTypes[format],
    });
    FileSaver.saveAs(blob, `${country}_${language}_${type}.${format}`);
  };

  return { exportMetaData };
};
