import { MetaDataEntryInput } from '../__generated__/types';

export const toMetaData = (md: MetaDataEntryInput): MetaDataEntryInput => {
  return {
    canonical: md.canonical,
    canonicalId: md.canonicalId,
    description: md.description,
    id: md.id,
    categoryId: md.categoryId,
    keywords: md.keywords,
    pageTitle: md.pageTitle,
    robots: md.robots,
    urlSlug: md.urlSlug,
    ogDescription: md.ogDescription,
    ogImage: md.ogImage,
    ogTitle: md.ogTitle,
    h1: md.h1,
    bodyCopy: md.bodyCopy,
    introText: md.introText,
    isOptimized: md.isOptimized,
    filterId: md.filterId,
    filterType: md.filterType,
    filterName: md.filterName,
    referenceUrl: md.referenceUrl,
    filters: md.filters,
  };
};
