import { ApolloLink, defaultDataIdFromObject, fromPromise, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export const cache = new InMemoryCache({
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'MetaDataEntry':
      case 'MetaDataVersion':
        return object._id;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

export const createAuthMiddleware = (getToken: () => Promise<string>) => {
  return new ApolloLink((operation, forward) => {
    return fromPromise(getToken()).flatMap((token) => {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return forward(operation);
    });
  });
};

export const createLogoutLink = (logout: () => Promise<string>) =>
  onError((errorResponse) => {
    if ((errorResponse.networkError as ServerError)?.statusCode === 401) {
      return fromPromise(logout() as unknown as Promise<{}>);
    }
  });

export const httpLink = new HttpLink({ uri: '/api/optimera/graphql' });
